/**
 * Deitz court reporting
 *
 * Deitz theme use AngularUI Router to manage routing and views
 * Each view are defined as state.
 * Initial there are written state for all view in theme.
 *
 */
function config($stateProvider, $httpProvider, $urlRouterProvider, $ocLazyLoadProvider, ivhTreeviewOptionsProvider, $cookiesProvider,KeepaliveProvider, IdleProvider) {

    $ocLazyLoadProvider.config({
        // Set to true if you want to see what and when is dynamically loaded
        debug: false
    });

    // lexitas holiday configure
    var getGivenDateOfMonth = function (startDate, dayOfWeek, weekNumber) {
        // Start of the month of the given startDate
        var myMonth = moment(startDate).startOf('month');
        // dayOfWeek of the first week of the month
        var firstDayOfWeek = myMonth.clone().weekday(dayOfWeek);
        // Check if first firstDayOfWeek is in the given month
        if( firstDayOfWeek.month() != myMonth.month() ){
            weekNumber++;
        }
        // Return result
        return firstDayOfWeek.add(weekNumber-1, 'weeks');
    }

    var lexitasHolidays = [];
    for(i=0;i<5;i++){
        lexitasHolidays.push(moment().startOf('year').add(i, 'years').format('MM/DD/YYYY')); // New Year’s Day, January 1st
        lexitasHolidays.push(moment().add(i, 'years').month('May').endOf('month').startOf('isoWeek').format('MM/DD/YYYY')); //memorial day
        lexitasHolidays.push(moment().add(i, 'years').month('July').set('date',4).format('MM/DD/YYYY')); // independence day
        lexitasHolidays.push(getGivenDateOfMonth(moment().add(i, 'years').month('september'),1,1).format('MM/DD/YYYY')); //labor day
        lexitasHolidays.push(getGivenDateOfMonth(moment().add(i, 'years').month('November'),4,4).format('MM/DD/YYYY')); // thanks giving thu
        lexitasHolidays.push(getGivenDateOfMonth(moment().add(i, 'years').month('November'),4,4).add(1,'days').format('MM/DD/YYYY')); // thanks giving fri
        lexitasHolidays.push(moment().add(i, 'years').month('December').set('date',24).format('MM/DD/YYYY')); // Christmas Eve 
        lexitasHolidays.push(moment().add(i, 'years').month('December').set('date',25).format('MM/DD/YYYY')); // Christmas Day
    }
      
    moment.locale('us', {
        holidays: lexitasHolidays,
        holidayFormat: 'MM/DD/YYYY'
    });

    // business day configure
    moment.locale('us', {
        workingWeekdays: [1,2,3,4,5]
    });

    $httpProvider.defaults.useXDomain = true;
    delete $httpProvider.defaults.headers.common['X-Requested-With'];

    $httpProvider.defaults.headers.common['Content-Type'] = 'application/json';
    $httpProvider.defaults.withCredentials = true;
    $httpProvider.interceptors.push('authInterceptor');

    ivhTreeviewOptionsProvider.set({
        idAttribute: 'value',
        labelAttribute: 'description',
        childrenAttribute: 'childern',
        selectedAttribute: 'selected',
        useCheckboxes: true,
        twistieCollapsedTpl: '<i class="fa fa-chevron-right"></i>',
        twistieExpandedTpl: '<i class="fa fa-chevron-down"></i>',
        twistieLeafTpl: '<i class="fa fa-caret-right"></i>'
    });

    // handle Idle activity
    IdleProvider.idle(1200);
    IdleProvider.timeout(1800);
    KeepaliveProvider.interval(900);

    ///deitz/public
    $cookiesProvider.defaults.path = cookie_path;
    $cookiesProvider.defaults.domain = document.location.host;

    var authenticated = ['$rootScope', '$q', '$cookies', '$state', '$http', function ($rootScope, $q, $cookies, $state, $http) {
        var deferred = $q.defer();
        setTimeout(function () {
            var token = $cookies.get("token");

            if (token) {
                deferred.resolve();
            } else {
                $state.go("login");
                deferred.reject();
            }
        }, 100);
        return deferred.promise;
    }];

    var checkUserPermission = function (permissionModule, permission) {
        return ['$rootScope', '$q', '$cookies', '$state', 'loginFactory', 'Notification', '$location', function ($rootScope, $q, $cookies, $state, loginFactory, Notification, $location) {
            var deferred = $q.defer();
            setTimeout(function () {
                var token = $cookies.get("token");
                if (token) {
                    if(Object.keys($rootScope.permissions || {}).length == 0 && localStorage.getItem('permissions_data')) {
                        $rootScope.permissions = JSON.parse(localStorage.getItem('permissions_data'));
                    }
                    if(Object.keys($rootScope.permissions_businessunits || {}).length == 0 && localStorage.getItem('permissions_businessunits_data')) {
                        $rootScope.permissions_businessunits = JSON.parse(localStorage.getItem('permissions_businessunits_data'));
                    }
                    if(Object.keys($rootScope.roles_businessunits || {}).length == 0 && localStorage.getItem('roles_businessunits')) {
                        $rootScope.roles_businessunits = JSON.parse(localStorage.getItem('roles_businessunits'));
                    }
                    if (Object.keys($rootScope.permissions || {}).length) {
                        var access = $rootScope.havePermission(permissionModule, permission);
                        if (!access && permissionModule != 'home') {
                            deferred.reject();

                            Notification.error($rootScope.nothavepermission ? $rootScope.nothavepermission : 'Sorry, you not have sufficient permission.');

                            $state.go('index.home');
                        } else {
                            deferred.resolve();
                        }
                    } else {
                        loginFactory.checkPermission(permission + "." + permissionModule).success(function (response) {
                            
                            localStorage.setItem('permissions_data', JSON.stringify(response.permissions));
                            localStorage.setItem('permissions_businessunits_data', JSON.stringify(response.permissions_businessunits));
                            localStorage.setItem('roles_businessunits', JSON.stringify(response.roles_businessunits));
                            
                            $rootScope.permissions = response.permissions;
                            $rootScope.permissions_businessunits = response.permissions_businessunits;
                            var auth_user = {};
                            $rootScope.roles_businessunits = response.roles_businessunits;
                            $rootScope.role = response.role;
                            $rootScope.user_role_id = Object.keys($rootScope.role)[0];
                            $rootScope.nothavepermission = response.message;

                            $rootScope.isSalesLogin = false;
                            // role 10 is used for sales, 12 for sales manager 
                            if($rootScope.role[10] !== undefined || $rootScope.role[12] !== undefined){
                                $rootScope.isSalesLogin = true;
                            }

                            $rootScope.$broadcast('batchCountBroadcastEvent', response.invoice_batch_queue); // set the invoice queue from database

                            if (!response.has_permission && permissionModule != 'home') {
                                Notification.error(response.message || 'Sorry, you not have sufficient permission.');

                                $state.go('index.home');
                                deferred.reject();
                            } else {
                                deferred.resolve();
                            }
                        }).error(function () {
                            deferred.reject();
                            $rootScope.logout();
                        });
                    }

                } else {
                    deferred.reject();
                    $cookies.remove('authUser');
                    $cookies.remove("audioperson");
                    $cookies.remove('token');
                    localStorage.removeItem('permissions_data')
                    localStorage.removeItem('permissions_businessunits_data')
                    localStorage.removeItem('roles_businessunits')
                    $state.go("login", {next: $location.path()});
                }
            }, 100);
            return deferred.promise;
        }];
    };

    $stateProvider
        .state('login', {
            url: "/login?next",
            templateUrl: "modules/login/login.html",
            data: {pageTitle: 'Login page', specialClass: 'login-page'},
            controller: 'loginController',
            resolve: {
                authenticated: ['$rootScope', '$q', '$cookies', '$state', '$location', '$stateParams', function ($rootScope, $q, $cookies, $state, $location, $stateParams) {
                    var deferred = $q.defer();
                    setTimeout(function () {
                        var token = $cookies.get("token");
                        var url = $location.absUrl();
                        
                        if($stateParams.next == '/selective-metrics' && (url.match(/token/g))){
                            var dashboard_token = url.split('=');
                            $cookies.put('dashboard_token',dashboard_token[1]);
                        }
                        if (token) {
                            deferred.reject(".....", token);
                            $state.go("index.home");
                        } else {
                            deferred.resolve("******", token);
                        }
                    }, 100);
                    return deferred.promise;
                }]
            }
        }).state('login_saml', {
            url: "/login_saml/:email/:token",
            templateUrl: "modules/login_saml/login.html",
            data: {pageTitle: 'Login page', specialClass: 'login-page'},
            controller: 'loginSamlController',
            resolve: {}
        })
        .state('forgot_password', {
            url: "/forgot_password",
            templateUrl: "modules/forgot_password/forgot_password.html",
            controller: 'forgotPasswordController',
            data: {pageTitle: 'Forgot password', specialClass: 'forgotpassword-page'},
            resolve: {}
        })
        .state('reset_password', {
            url: "/reset_password/:token?email",
            templateUrl: "modules/reset_password/reset_password.html",
            controller: 'resetPasswordController',
            data: {pageTitle: 'Reset password', specialClass: 'resetpassword-page'},
            resolve: {}
        })

        .state('index', {
            abstract: true,
            // url: "/index",
            templateUrl: "views/common/content.html"
        })

        .state('index.home', {
            url: "/home",
            templateUrl: "modules/home/home.html",
            controller: 'homeController',
            data: {pageTitle: 'Home'},
            resolve: {
                checkUserPermission: checkUserPermission('home', 'view')
            }
        })
        .state('index.dashboard', {
            url: "/selective-metrics?token",
            templateUrl: "modules/dashboard/dashboard.html",
            controller: 'dashboardController',
            data: {pageTitle: 'Selective Metrics'},
            resolve: { 
                checkUserPermission: checkUserPermission('dashboard', 'view.selective.metrics'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            serie: true,
                            files: ['css/plugins/c3/c3.min.css', 'js/plugins/d3/d3.min.js', 'js/plugins/c3/c3.min.js']
                        },
                        {
                            name: 'ngTagsInput',
                            files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                        },
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        },
                        {
                            serie: true,
                            name: 'gridshore.c3js.chart',
                            files: ['js/plugins/c3/c3-angular.min.js']
                        }
                    ]);
                }
            }
        })
        .state('index.dashboard_sales', {
            url: "/selective-metrics-sales?token",
            templateUrl: "modules/dashboard/dashboard_sales.html",
            controller: 'dashboardController',
            data: {pageTitle: 'Selective Metrics'},
            resolve: { 
                checkUserPermission: checkUserPermission('dashboard', 'view.selective.metrics'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            serie: true,
                            files: ['css/plugins/c3/c3.min.css', 'js/plugins/d3/d3.min.js', 'js/plugins/c3/c3.min.js']
                        },
                        {
                            name: 'ngTagsInput',
                            files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                        },
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        },
                        {
                            serie: true,
                            name: 'gridshore.c3js.chart',
                            files: ['js/plugins/c3/c3-angular.min.js']
                        }
                    ]);
                }
            }
        })
        .state('index.dashboard_beta', {
            url: "/selective-metrics-beta?token",
            templateUrl: "modules/dashboard/dashboard.html",
            controller: 'dashboardControllerBeta',
            data: {pageTitle: 'Selective Metrics Beta'},
            resolve: { 
                checkUserPermission: checkUserPermission('dashboard', 'view.selective.metrics.beta'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            serie: true,
                            files: ['css/plugins/c3/c3.min.css', 'js/plugins/d3/d3.min.js', 'js/plugins/c3/c3.min.js']
                        },
                        {
                            name: 'ngTagsInput',
                            files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                        },
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        },
                        {
                            serie: true,
                            name: 'gridshore.c3js.chart',
                            files: ['js/plugins/c3/c3-angular.min.js']
                        }
                    ]);
                }
            }
        })
        .state('index.job_profit', {
            url: "/job_profit?attId&billerId",
            templateUrl: "modules/code_tables/job_profit_report/browse.html",
            controller: 'jobProfitReportController',
            data: {pageTitle: 'Job Report'},
            resolve: {
                checkUserPermission: checkUserPermission('reports', 'view.job.profit'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        files: ['js/plugins/footable/footable.all.min.js', 'css/plugins/footable/footable.core.css']
                    },{
                        name: 'ui.footable',
                        files: ['js/plugins/footable/angular-footable.js']
                    },
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    }, {
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },
                    {
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                }
            }
        })
        .state('index.commission_report', {
            url: "/commission-report?attId",
            templateUrl: "modules/code_tables/commission_report/commission_report.html",
            controller: 'commissionReportController',
            data: {pageTitle: 'Commission Report'},
            resolve: {
                checkUserPermission: checkUserPermission('reports', 'view.commission.report'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        files: ['js/plugins/footable/footable.all.min.js', 'css/plugins/footable/footable.core.css']
                    },{
                        name: 'ui.footable',
                        files: ['js/plugins/footable/angular-footable.js']
                    },
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    }, {
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },
                    {
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                }
            }
        })
        .state('index.commission_report_new', {
            url: "/commission-report-new?attId",
            templateUrl: "modules/code_tables/commission_report_new/commission_report.html",
            controller: 'commissionReportNewController',
            data: {pageTitle: 'Commission Report (After Jan-2023)'},
            resolve: {
                checkUserPermission: checkUserPermission('reports', 'view.commission.report'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        files: ['js/plugins/footable/footable.all.min.js', 'css/plugins/footable/footable.core.css']
                    },{
                        name: 'ui.footable',
                        files: ['js/plugins/footable/angular-footable.js']
                    },
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    }, {
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },
                    {
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                }
            }
        })
        .state('index.commission_report_overhead', {
            url: "/commission-report-overhead?attId",
            templateUrl: "modules/code_tables/commission_report_overhead/commission_report_overhead.html",
            controller: 'commissionReportOverheadController',
            data: {pageTitle: 'Commission Report Overhead'},
            resolve: {
                checkUserPermission: checkUserPermission('reports', 'view.commission.overhead'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        files: ['js/plugins/footable/footable.all.min.js', 'css/plugins/footable/footable.core.css']
                    },{
                        name: 'ui.footable',
                        files: ['js/plugins/footable/angular-footable.js']
                    },
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    }, {
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },
                    {
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                }
            }
        })
        
        .state('index.commission_report_commissionable', {
            url: "/commission-report-commissionable?attId",
            templateUrl: "modules/code_tables/commission_report_commissionable/commission_report_commissionable.html",
            controller: 'commissionReportCommissionableController',
            data: {pageTitle: 'Commission Report Commissionable'},
            resolve: {
                checkUserPermission: checkUserPermission('reports', 'view.commission.commissionable'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        files: ['js/plugins/footable/footable.all.min.js', 'css/plugins/footable/footable.core.css']
                    },{
                        name: 'ui.footable',
                        files: ['js/plugins/footable/angular-footable.js']
                    },
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    }, {
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },
                    {
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                }
            }
        })
        .state('index.summary_billing_by_firm', {
            url: "/summary-billing-by-firm",
            templateUrl: "modules/code_tables/summary_billing_by_firm/browse.html",
            controller: 'summaryBillingByFirmController',
            data: {pageTitle: 'Summary Billing By Firm'},
            resolve: {
                checkUserPermission: checkUserPermission('reports', 'view.summary.billing.by.firm'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    }]);
                }
            }
        })
        .state('index.billing_by_firm_with_details', {
            url: "/billing-by-firm-with-details",
            templateUrl: "modules/code_tables/billing_by_firm_with_details/browse.html",
            controller: 'billingByFirmController.js',
            data: {pageTitle: 'Billing By Firm With Details'},
            resolve: {
                checkUserPermission: checkUserPermission('reports', 'view.summary.billing.by.firm'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    }]);
                }
            }
        })
        .state('index.detail_invoice_charges_by_client', {
            url: "/detail-invoice-charges-by-client",
            templateUrl: "modules/code_tables/detail_invoice_charges_by_client/browse.html",
            controller: 'detailInvoiceChargesByClient',
            data: {pageTitle: 'Detail Invoice Charges By Client'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, 
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('reports', 'view.detail.invoice.charges.by.client')
            }
        })
        .state('index.progressive_report', {
            url: "/progressive-report",
            templateUrl: "modules/code_tables/progressive_report/report.html",
            controller: 'progressiveReportController',
            data: {pageTitle: 'Progressive Report'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },{
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('reports', 'view.progressive.report')
            }
        })
        .state('index.invoice_line_item_report', {
            url: "/invoice-line-item-report",
            templateUrl: "modules/code_tables/invoice_line_item_report/report.html",
            controller: 'invoiceLineItemReportController',
            data: {pageTitle: 'Invoice Line Item Report'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },{
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('reports', 'view.progressive.report')
            }
        })
        .state('index.account_credits_report', {
            url: "/account-credits-report?attId",
            templateUrl: "modules/cash_receipt/account_credits_report.html",
            controller: 'accountCreditsReportController',
            data: {pageTitle: 'Account Credits Report'},
            resolve: {
                checkUserPermission: checkUserPermission('reports', 'view.account.credits'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        }, {
                            name: 'ngTagsInput',
                            files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                        },
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }]);
                }
            }
        })
        .state('index.operation_metrics', {
            url: "/operation-metrics",
            templateUrl: "modules/code_tables/operation_metrics/operation_metrics.html",
            controller: 'operationMetricsController',
            data: {pageTitle: 'Operation Metrics Report'},
            resolve: {
                checkUserPermission: checkUserPermission('dashboard', 'view.operation.metrics'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },
                    {
                        serie: true,
                        name: 'angular-ladda',
                        files: ['js/plugins/ladda/spin.min.js', 'js/plugins/ladda/ladda.min.js', 'css/plugins/ladda/ladda-themeless.min.css', 'js/plugins/ladda/angular-ladda.min.js']
                    },
                    {
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    }, {
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    }]);
                }
            }
        })
        .state('index.depositions_insights', {
            url: "/depositions-insights",
            templateUrl: "modules/code_tables/depositions_insights/depositions_insights.html",
            controller: 'depositionsInsightsController',
            data: {pageTitle: 'Depositions Insights'},
            resolve: {
                checkUserPermission: checkUserPermission('dashboard', 'view.depositions.insights'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        serie: true,
                        files: ['css/plugins/c3/c3.min.css', 'js/plugins/d3/d3.min.js', 'js/plugins/c3/c3.min.js']
                    },
                    {
                        serie: true,
                        name: 'gridshore.c3js.chart',
                        files: ['js/plugins/c3/c3-angular.min.js']
                    },
                    {
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    }, {
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    }]);
                }
            }
        })
        .state('index.system_notification',{
            url:"/system-notification",
            templateUrl:"modules/system_notification/system_notification.html",
            controller:'systemNotificationController',
            data:{pageTitle:'Send Monitor'},
            resolve: {
                checkUserPermission: checkUserPermission('send_monitor', 'view'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    }]);
                }
            }
        })
        //  CASH APP IMPLEMENTATION START
        .state('index.cash_receipt_details',{
            url:"/cash-receipt-details",
            templateUrl:"modules/code_tables/cashapp/cash_receipt_details.html",
            controller:'cashappReceiptDetailController',
            data:{pageTitle:'Cash Receipt Details'},
            resolve: {
                checkUserPermission: checkUserPermission('reports', 'view.cash.app.reports'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                    {
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    }
                ]);}
            }
        })
        
        .state('index.cash_receipt_summary',{
            url:"/cash-receipt-summary-report",
            templateUrl:"modules/code_tables/cashapp/cash_report_summary.html",
            controller:'cashappReceiptSummaryController',
            data:{pageTitle:'Cash Receipt Summary Report'},
            resolve: {
                checkUserPermission: checkUserPermission('reports', 'view.sku.report'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                ]);}
            }
        })
        // END OF CASH APP IMPLEMENTATION
        .state('index.sku_report',{
            url:"/sku-report",
            templateUrl:"modules/code_tables/sku_report/sku_report.html",
            controller:'skuReportController',
            data:{pageTitle:'Sku Report'},
            resolve: {
                checkUserPermission: checkUserPermission('reports', 'view.sku.report'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                ]);}
            }
        })
        .state('index.sku_report_legecy',{
            url:"/sku-report-legecy",
            templateUrl:"modules/code_tables/sku_report_legecy/sku_report.html",
            controller:'skuLegecyReportController',
            data:{pageTitle:'Sku Legecy Report'},
            resolve: {
                checkUserPermission: checkUserPermission('reports', 'view.sku.report'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                ]);}
            }
        })
        .state('index.lead_report',{
            url:"/lead-report",
            templateUrl:"modules/code_tables/sfdc_report/sfdc_report.html",
            controller:'sfdcReportController',
            data:{pageTitle:'Lead Report (attended but not called in)'},
            resolve: {
                checkUserPermission: checkUserPermission('reports', 'view.lead.report'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                    {
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    }]);
                }
            }
        })
        .state('index.client_dropped_report',{
            url:"/client-dropped-report",
            templateUrl:"modules/code_tables/client_dropped_report/client_dropped_report.html",
            controller:'clientDroppedReportController',
            data:{pageTitle:'Client Dropped Report'},
            resolve: {
                checkUserPermission: checkUserPermission('reports', 'view.client.dropped.report'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    },{
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    }]);
                }
            }
        })
        .state('index.open_invoice_report',{
            url:"/open-invoice-report",
            templateUrl:"modules/code_tables/open_invoice_report/open_invoice_report.html",
            controller:'openInvoiceReportController',
            data:{pageTitle:'Open Invoice Report'},
            resolve: {
                checkUserPermission: checkUserPermission('reports', 'view.open.invoice.report'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    },{
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },{
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    }]);
                }
            }
        })
        .state('index.legacy_invoice_report',{
            url:"/legacy-invoice-report",
            templateUrl:"modules/code_tables/legacy_invoice_report/legacy_invoice_report.html",
            controller:'legacyInvoiceReportController',
            data:{pageTitle:'Legacy Invoice Report'},
            resolve: {
                checkUserPermission: checkUserPermission('reports', 'view.legacy.invoice.report'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    },{
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },{
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    }]);
                }
            }
        })
        .state('index.reporting_sales_updates_report',{
            url:"/reporting-sales-updates-report",
            templateUrl:"modules/code_tables/reporting_sales_updates_report/reporting_sales_updates_report.html",
            controller:'reportingSalesUpdatesReportController',
            data:{pageTitle:'Reporting Account Executive(s) Updates Report'},
            resolve: {
                checkUserPermission: checkUserPermission('reports', 'view.reporting.account.executive.updates.report'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    },{
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },{
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    }]);
                }
            }
        })
        .state('index.job_status_report',{
            url:"/job-status-report",
            templateUrl:"modules/code_tables/job_status_report/job_status_report.html",
            controller:'jobStatusReportController',
            data:{pageTitle:'Job Status Report'},
            resolve: {
                checkUserPermission: checkUserPermission('reports', 'view.job.status.report'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    },{
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },{
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    }]);
                }
            }
        })
        .state('index.diary_report',{
            url:"/diary-report",
            templateUrl:"modules/code_tables/diary_report/diary_report.html",
            controller:'diaryReportController',
            data:{pageTitle:'Diary Report'},
            resolve: {
                checkUserPermission: checkUserPermission('reports','view.diary.report'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    },{
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },{
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    }]);
                }
            }
        })
        .state('index.daily_calendar_audit',{
            url:"/daily-calendar-audit",
            templateUrl:"modules/code_tables/daily_calendar_audit/daily_calendar_audit.html",
            controller:'dailyCalendarAuditController',
            data:{pageTitle:'Daily Calendar Audit'},
            resolve: {
                checkUserPermission: checkUserPermission('reports','view.diary.report'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    },
                    {
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },
                    {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },{
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    }]);
                }
            }
        })
        .state('index.invoice_profit_report',{
            url:"/invoice-profit-report",
            templateUrl:"modules/code_tables/invoice_profit_report/invoice_profit_report.html",
            controller:'invoiceProfitReportController',
            data:{pageTitle:'Invoice Profit Report'},
            resolve: {
                checkUserPermission: checkUserPermission('reports','view.invoice.profit.report'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                    {
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                    {
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    }]);
                }
            }
        })
        .state('index.exception_report',{
            url:"/exception-report",
            templateUrl:"modules/code_tables/exception_report/exception_report.html",
            controller:'exceptionReportController',
            data:{pageTitle:'Exception Report'},
            resolve: {
                checkUserPermission: checkUserPermission('reports','view.exception.report'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    },{
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },{
                        name: 'ui.toggle',
                        files: ['css/plugins/toggle-button/angular-bootstrap-toggle.min.css','js/plugins/toggle-button/angular-bootstrap-toggle.min.js']
                    },{
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    },{
                        name: 'ngPatternRestrict',
                        files: ['js/plugins/ng-pattern-restrict/ng-pattern-restrict.min.js']
                    }]);
                }
            }
        })
        .state('index.advised_payment',{
            url:"/advised-payment-report",
            templateUrl:"modules/code_tables/advised_payment/advised_payment_report.html",
            controller:'advisedPaymentReportController',
            data:{pageTitle:'Advised Payment Report'},
            resolve: {
                checkUserPermission: checkUserPermission('reports','view.advised.payment.report'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    },{
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },{
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    }]);
                }
            }
        })
        .state('index.import_data', {
            url: "/import-data",
            templateUrl: "modules/import_data/import.html",
            controller: 'importController',
            data:{pageTitle:'Import Data'},

            resolve: {
                checkUserPermission: checkUserPermission('import_data', 'import')
            }
        })
        /*Statement*/
        .state('statements', {
            abstract: true,
            url: "/statements",
            templateUrl: "views/common/content.html"
        })
        .state('statements.print_statements', {
            url: "/print-statements?clmId&attId&clamId",
            templateUrl: "modules/statements/statements.html",
            controller: 'statementController',
            data: {pageTitle: 'statements'},
            resolve: {
                checkUserPermission: checkUserPermission('attorney_firm', 'manage.statements'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        }, {
                            name: 'ngTagsInput',
                            files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                        },
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        },{
                            serie: true,
                            files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                        },{
                            name: 'daterangepicker',
                            files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                        }]);
                }
            }
        })
        .state('auto_send_statements', {
            abstract: true,
            url: "/auto-send-statements",
            templateUrl: "views/common/content.html"
        })
        .state('auto_send_statements.attorney_firm',{
            url:"/attorney-firm",
            templateUrl:"modules/statements/auto_send_statements/auto_send_statements.html",
            controller:'autoSendStatementsController',
            data:{pageTitle:'Auto Send Statements'},
            resolve: {
                checkUserPermission: checkUserPermission('attorney_firm', 'manage.statements'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, 
                    {
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }, {
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },{
                        name: 'ui.toggle',
                        files: ['css/plugins/toggle-button/angular-bootstrap-toggle.min.css','js/plugins/toggle-button/angular-bootstrap-toggle.min.js']
                    },{
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    }]);
                }
            }
        })
        .state('auto_send_statements.insurance_company',{
            url:"/insurance-company",
            templateUrl:"modules/statements/auto_send_statements/auto_send_insurance_company_statements.html",
            controller:'autoSendInsuranceCompanyStatementsController',
            data:{pageTitle:'Auto Send Statements'},
            resolve: {
                checkUserPermission: checkUserPermission('attorney_firm', 'manage.statements'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, 
                    {
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                    {
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }, {
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },{
                        name: 'ui.toggle',
                        files: ['css/plugins/toggle-button/angular-bootstrap-toggle.min.css','js/plugins/toggle-button/angular-bootstrap-toggle.min.js']
                    },{
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    }]);
                }
            }
        })
        .state('statement_message', {
            abstract: true,
            url: "/statement-message",
            templateUrl: "views/common/content.html"
        })
        .state('statement_message.browse', {
            url: "/browse",
            templateUrl: "modules/statements/statement_message/browse.html",
            controller: 'browseMessageController',
            data: {pageTitle: 'statement message'},
            resolve: {
                checkUserPermission: checkUserPermission('attorney_firm', 'manage.statements'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, 
                    {
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                    {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                }
            }
        })
        .state('statement_message.add', {
            url: "/add",
            templateUrl: "modules/statements/statement_message/statement_message.html",
            controller: 'statementMessageController',  
            data: {pageTitle: 'statement message'},
            resolve: {
                checkUserPermission: checkUserPermission('attorney_firm', 'manage.statements') 
            } 
        })
        .state('statement_message.edit', {
            url: "/edit?id",
            templateUrl: "modules/statements/statement_message/statement_message.html",
            controller: 'statementMessageController',  
            data: {pageTitle: 'statement message'},
            resolve: {
                checkUserPermission: checkUserPermission('attorney_firm', 'manage.statements') 
            }
        })
        .state('statements.invoice_bulk_reprint_by_firm', {
            url: "/invoice-bulk-reprint-by-firm",
            templateUrl: "modules/statements/invoice_bulk_reprint_by_firm/browse.html",
            controller: 'InvoiceBulkReprintByFirmController',
            data: {pageTitle: 'Reprint Invoices By Firm'},
            resolve: {
                checkUserPermission: checkUserPermission('attorney_firm', 'manage.statements'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        }, {
                            name: 'ngTagsInput',
                            files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                        },
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        },{
                            serie: true,
                            files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                        },{
                            name: 'daterangepicker',
                            files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                        },{
                            serie: true,
                            files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                        }, {
                            serie: true,
                            name: 'datatables',
                            files: ['js/plugins/dataTables/angular-datatables.min.js']
                        }, {
                            serie: true,
                            name: 'datatables.columnfilter',
                            files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                        }, {
                            serie: true,
                            name: 'datatables.buttons',
                            files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                        }]);
                }
            }
        })
        .state('statements.print_nycta_statements', {
            url: "/print-nycta-statements?attId",
            templateUrl: "modules/statements/nycta_statements.html",
            controller: 'nyctaStatementController',
            data: {pageTitle: 'Nycta statements'},
            resolve: {
                checkUserPermission: checkUserPermission('attorney_firm', 'manage.statements'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        }, {
                            name: 'ngTagsInput',
                            files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                        },
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        },{
                            serie: true,
                            files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                        },{
                            name: 'daterangepicker',
                            files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                        }]);
                }
            }
        })

        .state('statements.nycta_setup', {
            url: "/nycta-setup",
            templateUrl: "modules/statements/nycta_setup.html",
            controller: 'nyctaSetupController',
            data: {pageTitle: 'NYCTA SET-UP'},
            resolve: {
                checkUserPermission: checkUserPermission('attorney_firm', 'manage.statements'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'ngPatternRestrict',
                            files: ['js/plugins/ng-pattern-restrict/ng-pattern-restrict.min.js']
                        }
                     ]);
                 } 
                    
            }
        })
        /*Audit Login */
        .state('index.audit_login', {
            url: "/audit-login",
            templateUrl: "modules/login/browse.html",
            controller: 'auditLoginController',
            data: {pageTitle: 'Audit Login'},
            resolve: {
                checkUserPermission: checkUserPermission('audit_login', 'view'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                }
            }
        })

        /*knowledgw base pdf */
        .state('index.knowledgebase-guide', {
            url: "/knowledgebase-guide",
            templateUrl: "modules/knowledgebase/knowledgebase.html",
            controller: 'knowledgebaseController',
            data: {pageTitle: 'Knowledge Base Guide'},
            resolve: {
                checkUserPermission: checkUserPermission('knowledgebase-guide', 'update'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                }
            }
        })

        /* Client Guide */
        .state('index.client-guide', {
            url: "/client-guide",
            templateUrl: "modules/client_guide/client_guide.html",
            controller: 'clientGuideController',
            data: {pageTitle: 'Client Guide'},
            resolve: {
                checkUserPermission: checkUserPermission('client-guide', 'update'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                }
            }
        })

        /* Reporter Guide */
        .state('index.reporter-guide', {
            url: "/reporter-guide",
            templateUrl: "modules/reporter_guide/reporter_guide.html",
            controller: 'reporterGuideController',
            data: {pageTitle: 'Reporter Guide'},
            resolve: {
                checkUserPermission: checkUserPermission('reporter-guide', 'update'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                }
            }
        })
  
        /*Attorney Firms*/
        .state('firms', {
            abstract: true,
            url: "/firms",
            templateUrl: "views/common/content.html"
        })
        .state('firms.browse', {
            url: "/browse",
            templateUrl: "modules/firms/browse/browse.html",
            controller: 'browseController',
            data: {pageTitle: 'Browse Firm'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    },{
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    },{
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },{
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                    {
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },{
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('attorney_firm', 'view')
            }
        })
        .state('firms.add', {
            url: '/',
            templateUrl: "modules/firms/attorneyfirm.html",
            data: {pageTitle: 'Add Firm'},
            controller: 'addfirmsController',
            resolve: {
                checkUserPermission: checkUserPermission('attorney_firm', 'create'),
                $uibModalInstance: function () {
                    return {};
                },
                elawId : function(){
                    return null;
                }


            }
        })
        .state('firms.findattorneyfirm', {
            url: "/find",
            templateUrl: "modules/firms/findfirm/findfirm.html",
            controller: 'findfirmController',
            data: {pageTitle: 'Find firm if exists'},
            resolve: {
                // Above state is not using so put same as well
                checkUserPermission: checkUserPermission('attorney_firm', 'create')
            }
        })
        .state('firms.add.step_one_add', {
            url: 'step_one?elawId',
            templateUrl: 'modules/firms/step_one.html',
            data: {pageTitle: 'Address Information'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                    {
                        name: 'ngPatternRestrict',
                        files: ['js/plugins/ng-pattern-restrict/ng-pattern-restrict.min.js']
                    },{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },                    
                    {
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },
                    {
                        files: ['css/plugins/working-hours/working-hours.css', 'css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }
                ]);
                },
                checkUserPermission: checkUserPermission('attorney_firm', 'create')
            }
        })
        .state('firms.add.step_two_add', {
            url: 'step_two',
            templateUrl: 'modules/firms/step_two.html',
            data: {pageTitle: 'Rate Information'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen-add-option.js', 'js/plugins/chosen/chosen.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('attorney_firm', 'create')
            }
        })
        .state('firms.add.step_three_add', {
            url: 'step_three',
            templateUrl: 'modules/firms/step_three.html',
            data: {pageTitle: 'Other Information'},
            resolve: {
                checkUserPermission: checkUserPermission('attorney_firm', 'create'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        { 
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']  
                        }
                    ]);
                }
            }
        })
        .state('firms.manage', {
            url: '/',
            templateUrl: "modules/firms/attorneyfirm.html",
            data: {pageTitle: 'Add Firm'},
            controller: 'firmsController',
            resolve: {
                checkUserPermission: checkUserPermission('attorney_firm', 'edit'),
                $uibModalInstance: function () {
                    return {};
                },
                attorneyFirmId : function(){
                    return null;
                }
            }
            // authenticated: authenticated
        })
        .state('firms.manage.step_one', {
            url: 'step_one/:id',
            templateUrl: 'modules/firms/step_one.html',
            data: {pageTitle: 'Address Information'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                    {
                        name: 'ngPatternRestrict',
                        files: ['js/plugins/ng-pattern-restrict/ng-pattern-restrict.min.js']
                    },    
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },                    
                    {
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },
                    {
                        files: ['css/plugins/working-hours/working-hours.css', 'css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },
                ]);
                },
                checkUserPermission: checkUserPermission('attorney_firm', 'edit')
            }
        })
        .state('firms.manage.step_two', {
            url: 'step_two/:id',
            templateUrl: 'modules/firms/step_two.html',
            data: {pageTitle: 'Rate Information'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen-add-option.js', 'js/plugins/chosen/chosen.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('attorney_firm', 'edit')
            }
        })
        .state('firms.manage.step_three', {
            url: 'step_three/:id',
            templateUrl: 'modules/firms/step_three.html',
            data: {pageTitle: 'Other Information'},
            resolve: {
                checkUserPermission: checkUserPermission('attorney_firm', 'edit'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        { 
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']  
                        },
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        }
                    ]);
                }
            }
        })
        .state('firms.manage.ebt', {
            url: ":id/ebt",
            templateUrl: "modules/firms/ebt.html",
            data: {pageTitle: 'Admins'},
            resolve: {
                checkUserPermission: checkUserPermission('attorney_firm', 'manage_ebt'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        { 
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']  
                        }
                    ]);
                }
            }
        })
        .state('firms.manage.credit_card_info', {
            url: 'credit-card-info/:id',
            templateUrl: 'modules/firms/credit_card_info.html',
            data: {pageTitle: 'Credit Card Information'},
            resolve: {
                checkUserPermission: checkUserPermission('attorney_firm', 'edit')
            }
        })
        .state('firms.manage.commission', {
            url: "commission/:id",
            templateUrl: "modules/firms/commission.html",
            data: {pageTitle: 'Commission'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen-add-option.js', 'js/plugins/chosen/chosen.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('attorney_firm', 'manage_commission')
            }
        })
        // Notes states  // update persmission for notes
        .state('firms.manage.notes', {
            url: "notes/:id",
            templateUrl: "modules/firms/firmnotes/notes.html",
            data: {pageTitle: 'Notes'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen-add-option.js', 'js/plugins/chosen/chosen.js']
                    },{
                       files:['js/plugins/angular-file-saver/angular-file-upload.min.js','js/plugins/angular-file-saver/file-saver.js'] 
                    },
                    {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }
                ]);
                },
                checkUserPermission: checkUserPermission('attorney_firm', 'manage_notes')
            }
        })
        .state('firms.merge_attorney', {
            url: "/merge-attorney-firms",
            templateUrl: "modules/firms/mergefirm/merge_firms.html",
            controller: 'mergeAttorneyFirmsController',
            data: {pageTitle: 'Merge attorney firms'},
            resolve: {
                // Above state is not using so put same as well
                checkUserPermission: checkUserPermission('attorney_firm', 'merge')
            }
        })
        .state('firms.goldenRecords', {
            url: "/golden-records",
            templateUrl: "modules/firms/browse/goldenRecords.html",
            controller: 'firmsGoldenRecordsController',
            data: {pageTitle: 'Firm Golden Records'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    },{
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    },{
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },{
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                    {
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },{
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('attorney_firm', 'merge')
            }
        })
        .state('index.firm_rates', {
            url: "/firm-rates/browse",
            templateUrl: "modules/firms/firm_rates/firm_rates.html",
            controller: 'firmRatesController',
            data: {pageTitle: 'Rate Change Global'},
            resolve: {
               
                 loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }, {
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },{
                        insertBefore: '#loadBefore',
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('firm_rate', 'view')
            }
        })
        .state('firms.manage_salesforce', {
            url: "/salesforce/browse",
            templateUrl: "modules/firms/firm_salesforce/browse.html",
            controller: 'firmSalesForceController',
            data: {pageTitle: 'Manage SalesForce'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    },{
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    },{
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },{
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    }]);
                },
                checkUserPermission: checkUserPermission('attorney_firm', 'view')
            }
        })
        .state('firms.find_commission_by_salesman', {
            url: "/find-commission",
            templateUrl: "modules/firms/findcommission/find.html",
            controller: 'findCommissionController',
            data: {pageTitle: 'Find commission'},
            resolve: {
                checkUserPermission: checkUserPermission('attorney_firm', 'manage_commission'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    }]);
                }
            }
        })
        .state('firms.manage_commission', {
            url: "/manage-commission/browse/:id",
            templateUrl: "modules/firms/manage_commission/browse.html",
            controller: 'manageCommissionController',
            data: {pageTitle: 'Manage Commission'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    },{
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    },{
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },{
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    }]);
                },
                checkUserPermission: checkUserPermission('attorney_firm', 'manage_commission')
            }
        })

        /* Attorney*/
        .state('attorney', {
            abstract: true,
            url: "/attorney",
            templateUrl: "views/common/content.html",
        })
        .state('attorney.browse', {
            url: "/browse?firmId",
            templateUrl: "modules/attorneys/browse.html",
            controller: 'browseAttorneyController',
            data: {pageTitle: 'Browse Attorneys'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'ngTagsInput',
                            files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                        },
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        },
                    ]);
                },
                checkUserPermission: checkUserPermission('attorney', 'view')
            }
        })
        .state('attorney.addattorneytofirm', {
            url: "/addattorneytofirm?firm_id&from",
            templateUrl: "modules/addattorneytofirm/addattorneytofirm.html",
            controller: 'addattorneytofirmController',
            data: {pageTitle: 'Add An Attorney'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('attorney', 'create'),
                $uibModalInstance: function () {
                    return {};
                },
                attorneyFirmId : function(){
                    return null;
                },
                email : function(){
                    return null;
                }
            }
        })
        .state('attorney.editattorneytofirm', {            
            url: "/editattorneytofirm?id&firm_id&from",
            templateUrl: "modules/addattorneytofirm/addattorneytofirm.html",
            controller: 'editattorneytofirmController',
            data: {pageTitle: 'Edit Attorney'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('attorney', 'edit'),
                $uibModalInstance: function () {
                    return {};
                },
                attorneyId : function(){
                    return null;
                }
            }
        })
        .state('attorney.merge_attorneys', {
            url: "/merge-attorneys",
            templateUrl: "modules/addattorneytofirm/merge_attorneys/merge_attorneys.html",
            controller: 'mergeAttorneysController',
            data: {pageTitle: 'Merge Attorneys'},
            resolve:{
                checkUserPermission: checkUserPermission('attorney', 'merge'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    }]);
                },
            }
        })
/*Reporter Work Types*/
.state('code_tables.reporter_work_types', {
    url: "/reporter_work_types/browse",
    templateUrl: "modules/code_tables/reporter_work_types/browse.html",
    controller: 'browseReporterWorkTypesController',
    resolve: {
        loadPlugin: function ($ocLazyLoad) {
            return $ocLazyLoad.load([{
                serie: true,
                files: ['js/plugins/dataTables/datatables.min.js','css/plugins/dataTables/datatables.min.css']
            }, {
                serie: true,
                name: 'datatables',
                files: ['js/plugins/dataTables/angular-datatables.min.js']
            }]);
        },
        checkUserPermission: checkUserPermission('locations', 'view')
    }
})
.state('code_tables.reporter_work_types_add', {
    url: "/reporter_work_types/add",
    templateUrl: "modules/code_tables/reporter_work_types/form.html",
    controller: 'addReporterWorkTypesController',
    data: {pageTitle: 'Add Resource Work Types'},
    resolve: {
        loadPlugin: function ($ocLazyLoad) {
            return $ocLazyLoad.load([]);
        },
        checkUserPermission: checkUserPermission('locations', 'create')
    }
})
    .state('code_tables.reporter_work_types_edit', {
        url: "/reporter_work_types/edit/:id",
        templateUrl: "modules/code_tables/reporter_work_types/form.html",
        controller: 'addReporterWorkTypesController',
        data: {pageTitle: 'Edit Resource Work Types'},
        resolve: {
            loadPlugin: function ($ocLazyLoad) {
            return $ocLazyLoad.load([{
                serie: true,
                    files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                }]);
            },
            checkUserPermission: checkUserPermission('locations', 'edit')
        }
    })



        /* Account receivable notes for attorney */
        .state('accountReceivableNotes', {
            abstract: true,
            url: "/accountReceivableNotes",
            templateUrl: "views/common/content.html"
        })
        .state('accountReceivableNotes.browse', {
            url: "/browse?attId&clmId&cpid&attNameId",
            templateUrl: "modules/accountReceivableNotes/browse.html",
            controller: 'accountReceivableNotesController',
            data: {pageTitle: 'Account receivable notes & reminder'},
            resolve: {
                checkUserPermission: checkUserPermission('account_receivable_notes_attorney', 'view'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    },{
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },{
                        serie: true,
                        name: 'angular-ladda',
                        files: ['js/plugins/ladda/spin.min.js', 'js/plugins/ladda/ladda.min.js', 'css/plugins/ladda/ladda-themeless.min.css', 'js/plugins/ladda/angular-ladda.min.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    }]);
                }
            }
        })
        .state('accountReceivableNotes.scheduled_calls',{
            url:"/scheduled-calls",
            templateUrl:"modules/accountReceivableNotes/scheduled_calls.html",
            controller:'scheduledCallsController',
            data: {pageTitle: 'A/R Scheduled Calls'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },
                    {
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },{
                        name: 'ui.toggle',
                        files: ['css/plugins/toggle-button/angular-bootstrap-toggle.min.css','js/plugins/toggle-button/angular-bootstrap-toggle.min.js']
                    }
                ]);
                },
                checkUserPermission: checkUserPermission('account_receivable_notes_attorney', 'scheduledcalls')

            }
        })
        .state('accountReceivableNotes.completed_calls',{
            url:"/completed-calls",
            templateUrl:"modules/accountReceivableNotes/done_calls.html",
            controller:'doneCallsController',
            data: {pageTitle: 'A/R Completed Calls'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },
                    {
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('account_receivable_notes_attorney', 'completedcalls'),

            }
        })

        // reassign collector
        .state('accountReceivableNotes.reassign_collector',{
            url:'/reassign-collector',
            templateUrl:'modules/accountReceivableNotes/reassign_collector.html',
            controller: 'reassignCollector',
            data:{pageTitle:'Reassign Collector'},
            resolve:{
                loadPlugin: function($ocLazyLoad){
                    return $ocLazyLoad.load([
                        {
                            serie: true,
                            files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                        },{
                            serie: true,
                            name: 'datatables',
                            files: ['js/plugins/dataTables/angular-datatables.min.js']
                        }, {
                            serie: true,
                            name: 'datatables.light-columnfilter',
                            files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                        },
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        },
                        {
                            serie: true,
                            name: 'datatables.columnfilter',
                            files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                        },{
                            serie: true,
                            name: 'datatables.buttons',
                            files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                        },{
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        },
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        }

                    ]);
                },
                checkUserPermission:checkUserPermission('account_receivable_notes_attorney', 'completedcalls'),
                
            }

        })


        /*Charges*/
        .state('charges', {
            abstract: true,
            url: "/charges",
            templateUrl: "views/common/content.html"
        })
        .state('charges.create', {
            url: "/create",
            templateUrl: "modules/create_charge/charge.html",
            controller: 'createchargesController',
            data: {pageTitle: 'Add Charge'},
            resolve: {
                checkUserPermission: checkUserPermission('charges', 'create')
            }
        })
        .state('charges.browse', {
            url: "/browse",
            templateUrl: "modules/charges/browse.html",
            controller: 'chargesController',
            data: {pageTitle: 'Charges'},
            resolve: {
                checkUserPermission: checkUserPermission('charges', 'view')
            }
        })

        //Reporter Module Start
        .state('representative', {
            abstract: true,
            url: "/resource",
            templateUrl: "views/common/content.html",
        })
        .state('representative.browse', {
            url: "/browse",
            templateUrl: "modules/representative/browse/browse.html",
            controller: 'representativeController',
            data: {pageTitle: 'Browse Representative'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }, {
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('representative_types', 'view')
            }
        })
        .state('representative.add', {
            url: "/add",
            templateUrl: "modules/representative/add/representative.html",
            controller: 'representativeaddController',
            data: {pageTitle: 'Add Representative'},
            resolve: {
                checkUserPermission: checkUserPermission('representative_types', 'create')
            }
        })
        .state('representative.reporter', {
            url: '/reporter/',
            templateUrl: "modules/representative/addreporter/add.html",
            data: {pageTitle: 'Add Primary Resource'},
            controller: 'reporterController',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['css/plugins/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox.css']
                        },{
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }
                    ]);
                },
                checkUserPermission: checkUserPermission('representative_types', 'create.reporter')
            }
        })
        .state('representative.reporter.step_one', {
            url: 'step_one/:id',
            templateUrl: 'modules/representative/addreporter/step_one.html',
            data: {pageTitle: 'Address Information'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'ngPatternRestrict',
                            files: ['js/plugins/ng-pattern-restrict/ng-pattern-restrict.min.js']
                        }
                    ]);
                    } 
                }
        })
        .state('representative.reporter.step_two', {
            url: 'step_two/:id',
            templateUrl: 'modules/representative/addreporter/step_two.html',
            data: {pageTitle: 'Rate Information'},
            resolve: {
                checkUserPermission: checkUserPermission('representative_types', 'show.rate.information')
            }
        })
        .state('representative.reporter.step_three', {
            url: 'step_three/:id',
            templateUrl: 'modules/representative/addreporter/step_three.html',
            data: {pageTitle: 'Other Information'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                       
                        {
                            files: ['js/plugins/working-hours/working-hours.js']
                        },
                        {
                            files: ['css/plugins/working-hours/working-hours.css']
                        },
                        {
                            serie: true,
                            files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                        }, {
                            serie: true,
                            name: 'datatables',
                            files: ['js/plugins/dataTables/angular-datatables.min.js']
                        }
                    ]);
                    } 
                    }
        })
        .state('representative.reporter.step_four', {
            url: 'step_four/:id',
            templateUrl: 'modules/representative/addreporter/step_four.html',
            data: {pageTitle: 'Private Information'},
            resolve: {
                checkUserPermission: checkUserPermission('allow_access_without_password', 'allow.resource.private.info')
            }
        })
        .state('representative.reporter.general_information', {
            url: 'general_information/:id',
            templateUrl: 'modules/representative/addreporter/general_information.html',
            data: {pageTitle: 'General Information'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                       
                        {
                            files: ['js/plugins/working-hours/working-hours.js']
                        },
                        {
                            files: ['css/plugins/working-hours/working-hours.css']
                        },
                       
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        },
                        {
                            name: 'ngTagsInput',
                            files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                        },
                        ,{
                            serie: true,
                            files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                        },{
                            name: 'daterangepicker',
                            files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                        }
                       
                    ]);
                } 
               
            }
        })
       
        .state('representative.merge_representative',{
            url: "/merge-resource",
            templateUrl: "modules/representative/merge/merge_resource.html",
            controller: 'mergeRepresentativeController',
            data: {pageTitle: 'Merge Resource'},
            resolve: {
                checkUserPermission: checkUserPermission('representative_types', 'merge')
            }
        })
        .state('representative.certification_expiration',{
            url:"/certification-expiration",
            templateUrl:"modules/representative/certification_expiration/certification_expiration.html",
            controller:'certificationExpirationController',
            data:{pageTitle:'Certification Expiration'},
            resolve: {
                checkUserPermission: checkUserPermission('representative_types', 'view.certification.expiration'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    },{
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },{
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    }]);
                }
            }
        })
        .state('representative.timeoffs',{
            url:"/timeoffs",
            templateUrl:"modules/representative/timeoffs/timeoffs.html",
            controller:'timeOffsController',
            data:{pageTitle:'Reporter Timeoffs'},
            resolve: {
                checkUserPermission: checkUserPermission('representative_types', 'view.timeoffs'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    },{
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },{
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    },{
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    }]);
                }
            }
        })
        .state('representative.work_in_progress_by_reporter',{
            url: "/work-in-progress-report?repId&attId",
            templateUrl: "modules/representative/work_in_progress_by_reporter.html",
            controller: 'workInProgressByReporterController',
            data: {pageTitle: 'Work in progress By resource/attorney'},
            resolve: {
                checkUserPermission: checkUserPermission('reports', 'view.work.in.progress.by.reporter.or.attorney'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        files: ['js/plugins/footable/footable.all.min.js', 'css/plugins/footable/footable.core.css']
                    },{
                        name: 'ui.footable',
                        files: ['js/plugins/footable/angular-footable.js']
                    },{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                }
            }
        })
        //Reporter Module End

        //Account Executive Module Start
        .state('representative.sales', {
            url: '/sales/',
            templateUrl: "modules/representative/addsales/add.html",
            data: {pageTitle: 'Add Salesperson'},
            controller: 'salespersonController',
            resolve: {
                checkUserPermission: checkUserPermission('representative_types', 'create.sales')
            }
        })
        .state('representative.sales.step_one', {
            url: 'step_one/:id',
            templateUrl: 'modules/representative/addsales/step_one.html',
            data: {pageTitle: 'Address Information'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'ngPatternRestrict',
                            files: ['js/plugins/ng-pattern-restrict/ng-pattern-restrict.min.js']
                        }
                    ]);
                    } 
                }
        })
        .state('representative.sales.step_two', {
            url: 'step_two/:id',
            templateUrl: 'modules/representative/addsales/step_two.html',
            data: {pageTitle: 'Other information'},
        })
        .state('representative.sales.step_three', {
            url: 'step_three/:id',
            templateUrl: 'modules/representative/addsales/step_three.html',
            data: {pageTitle: 'Private Information'},
        })
        //Account Executive Module End
        //Typist Module Start
        .state('representative.typist', {
            url: '/typist/',
            templateUrl: "modules/representative/addtypist/add.html",
            data: {pageTitle: 'Add Typist'},
            controller: 'typistController',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['css/plugins/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox.css']
                        }
                    ]);
                },
                checkUserPermission: checkUserPermission('representative_types', 'create.typist')
            }
        })
        .state('representative.typist.step_one', {
            url: 'step_one/:id',
            templateUrl: 'modules/representative/addtypist/step_one.html',
            data: {pageTitle: 'Address Information'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'ngPatternRestrict',
                            files: ['js/plugins/ng-pattern-restrict/ng-pattern-restrict.min.js']
                        }
                    ]);
                    } 
                }
        })
        .state('representative.typist.step_two', {
            url: 'step_two/:id',
            templateUrl: 'modules/representative/addtypist/step_two.html',
            data: {pageTitle: 'Rate Information'},
            resolve: {
                checkUserPermission: checkUserPermission('representative_types', 'show.rate.information')
            }
        })
        .state('representative.typist.step_three', {
            url: 'step_three/:id',
            templateUrl: 'modules/representative/addtypist/step_three.html',
            data: {pageTitle: 'Other Information'},
        })
        .state('representative.typist.step_four', {
            url: 'step_four/:id',
            templateUrl: 'modules/representative/addtypist/step_four.html',
            data: {pageTitle: 'Private Information'},
        })
        //Typist Module End
        /*//Scopist Module Start
        .state('representative.scopist', {
            url: '/scopist/',
            templateUrl: "modules/representative/addscopist/add.html",
            data: {pageTitle: 'Add Scopist'},
            controller: 'scopistController',
            resolve: {
                checkUserPermission: checkUserPermission('representative_types', 'create.scopist')
            }
        })
        .state('representative.scopist.step_one', {
            url: 'step_one/:id',
            templateUrl: 'modules/representative/addscopist/step_one.html',
            data: {pageTitle: 'Address Information'},
        })
        .state('representative.scopist.step_two', {
            url: 'step_two/:id',
            templateUrl: 'modules/representative/addscopist/step_two.html',
            data: {pageTitle: 'Rate Information'},
        })
        .state('representative.scopist.step_three', {
            url: 'step_three/:id',
            templateUrl: 'modules/representative/addscopist/step_three.html',
            data: {pageTitle: 'Other Information'},
        })*/

        //Diary Module Start
        .state('diary', {
            abstract: true,
            url: "/diary",
            templateUrl: "views/common/content.html"
        })
        .state('diary.findcase', {
            url: "/find",
            templateUrl: "modules/diary/findcase/findcase.html",
            controller: 'findcaseController',
            data: {pageTitle: 'Find if case exists'},
            resolve: {
                checkUserPermission: checkUserPermission('diary', 'view'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'ngTagsInput',
                            files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                        },
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        },
                        {
                            serie: true,
                            files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                        },{
                            name: 'daterangepicker',
                            files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                        }
                    ]);
                }

            }
        })
        .state('diary.caselist', {
            url: '/list/',
            templateUrl: "modules/diary/caselist/list.html",
            data: {pageTitle: 'List Calendar case'},
            controller: 'caselistController',
            authenticated: authenticated,
            resolve: {
                checkUserPermission: checkUserPermission('diary', 'view'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['css/plugins/dataTables/datatables208.min.css','js/plugins/dataTables/datatables208.min.js','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    }, 
                    /*{
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },
                    {
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },
                    {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    },*/
                        {
                            serie: true,
                            name: 'angular-ladda',
                            files: ['js/plugins/ladda/spin.min.js', 'js/plugins/ladda/ladda.min.js', 'css/plugins/ladda/ladda-themeless.min.css', 'js/plugins/ladda/angular-ladda.min.js']
                        },
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        },
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        },
                        {
                            name: 'ui.toggle',
                            files: ['css/plugins/toggle-button/angular-bootstrap-toggle.min.css','js/plugins/toggle-button/angular-bootstrap-toggle.min.js']
                        },
                        {
                            serie: true,
                            files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                        },{
                            name: 'daterangepicker',
                            files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                        }
                    ]);
                }
            }
        })
        .state('diary.pendingJobs', {
            url: '/pendingJobs?lookingFor',
            templateUrl: "modules/diary/pendingJobs/pendingJobList.html",
            data: {pageTitle: 'Pending Jobs'},
            controller: 'pendingJobListController',
            authenticated: authenticated,
            resolve: {
                checkUserPermission: checkUserPermission('diary', 'view.pending.jobs'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },
                    {
                        serie: true,
                        name: 'angular-ladda',
                        files: ['js/plugins/ladda/spin.min.js', 'js/plugins/ladda/ladda.min.js', 'css/plugins/ladda/ladda-themeless.min.css', 'js/plugins/ladda/angular-ladda.min.js']
                    },
                    {
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },{
                        name: 'ui.toggle',
                        files: ['css/plugins/toggle-button/angular-bootstrap-toggle.min.css','js/plugins/toggle-button/angular-bootstrap-toggle.min.js']
                    },{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    }]);
                }
            }
        })
        .state('diary.cancelledJobs', {
            url: '/cancelledJobs',
            templateUrl: "modules/diary/cancelledJobs/cancelledJobList.html",
            data: {pageTitle: 'Cancelled Jobs'},
            controller: 'cancelledJobListController',
            authenticated: authenticated,
            resolve: {
                checkUserPermission: checkUserPermission('diary', 'view.cancelled.jobs'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },
                    {
                        serie: true,
                        name: 'angular-ladda',
                        files: ['js/plugins/ladda/spin.min.js', 'js/plugins/ladda/ladda.min.js', 'css/plugins/ladda/ladda-themeless.min.css', 'js/plugins/ladda/angular-ladda.min.js']
                    },
                    {
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },{
                        name: 'ui.toggle',
                        files: ['css/plugins/toggle-button/angular-bootstrap-toggle.min.css','js/plugins/toggle-button/angular-bootstrap-toggle.min.js']
                    },{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },{
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    }]);
                }
            }
        })
        .state('invoice.hold_review_invoice', {
            url: '/hold_review_invoice',
            templateUrl: "modules/invoice/holdreview/holdreview.html",
            data: {pageTitle: 'HOLD For Review Invoice'},
            controller: 'holdreviewController',
            authenticated: authenticated,
            resolve: {
                checkUserPermission: checkUserPermission('invoice', 'view'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    },{
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },{
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    }, {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    }]);
                }
            }
        })
        .state('invoice.hold_netsuite_invoice', {
            url: '/hold-invoice-for-netsuite',
            templateUrl: "modules/invoice/holdnetsuite/holdnetsuite.html",
            data: {pageTitle: 'HOLD For Netsuite Invoice'},
            controller: 'holdnetsuiteController',
            authenticated: authenticated,
            resolve: {
                checkUserPermission: checkUserPermission('invoice', 'view'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    },{
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },{
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    }, {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                }
            }
        })
        .state('invoice.jobs_marked_ready_for_invoice', {
            url: '/jobs-marked-ready-for-invoice',
            templateUrl: "modules/invoice/markedJobs/markedJobs.html",
            data: {pageTitle: 'Jobs Marked Ready to Invoice'},
            controller: 'markedJobsController',
            authenticated: authenticated,
            resolve: {
                checkUserPermission: checkUserPermission('invoice', 'view'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    },{
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },{
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    }, {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    }]);
                }
            }
        })
        .state('invoice.jobs_not_marked_ready_for_invoice', {
            url: '/jobs-not-marked-ready-for-invoice',
            templateUrl: "modules/invoice/markedJobs/notMarkedJobs.html",
            data: {pageTitle: 'Jobs In Production Flow'},
            controller: 'notMarkedJobsController',
            authenticated: authenticated,
            resolve: {
                checkUserPermission: checkUserPermission('invoice', 'view'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    },{
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    }]);
                }
            }
        })
        .state('diary.job_schedules', {
            url: '/job-schedules',
            templateUrl: "modules/diary/jobSchedules/jobSchedules.html",
            data: {pageTitle: 'Job Schedules'},
            controller: 'jobSchedulesController',
            authenticated: authenticated,
            resolve: {
                checkUserPermission: checkUserPermission('diary', 'view'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    }, {
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },
                    {
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },{
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    }]);
                }
            }
        })
        .state('diary.attendee_queue', {
            url: '/attendee-queue',
            templateUrl: "modules/diary/addAttendee/browse.html",
            data: {pageTitle: 'Attendee Queue'},
            controller: 'attendeeQueueController',
            authenticated: authenticated,
            resolve: {
                checkUserPermission: checkUserPermission('diary', 'view'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    },{
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },{
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                }
            }
        })
        .state('diary.manage_pre_deposition_exhibits', {
            url: '/manage-pre-deposition-exhibits',
            templateUrl: "modules/diary/manage_exhibits/browse.html",
            data: {pageTitle: 'Manage Notice & Pre-Deposition Exhibits'},
            controller: 'managePreDepositionExhibitsController',
            authenticated: authenticated,
            resolve: {
                checkUserPermission: checkUserPermission('diary', 'view'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    },{
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },{
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                }
            }
        })
        .state('diary.adduser', {
            url: "/adduser?attorney_id",
            templateUrl: "modules/diary/addUser/add_user_diary.html",
            controller: 'addUserToDiaryController',
            data: {pageTitle: 'Add User'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('attorney', 'create')
            }
        })
        .state('diary.addcase', {
            url: '/add/',
            templateUrl: "modules/diary/addcase/addcase.html",
            data: {pageTitle: 'Add Calendar'},
            controller: 'addcaseController',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },                        {
                        name: 'ui.toggle',
                        files: ['css/plugins/toggle-button/angular-bootstrap-toggle.min.css','js/plugins/toggle-button/angular-bootstrap-toggle.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('diary', 'create')
            }
        })
        .state('diary.addcase.step_one', {
            url: 'step_one',
            templateUrl: 'modules/diary/addcase/step_two.html',
            data: {pageTitle: 'Firm Information'},
            resolve:{
                loadPlugin:function($ocLazyLoad){
                    return $ocLazyLoad.load([
                        {
                            name: 'ngPatternRestrict',
                            files: ['js/plugins/ng-pattern-restrict/ng-pattern-restrict.min.js']
                        },
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        },
                        {
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    }]);
                }
            }
        })
        .state('diary.addcase.step_two', {
            url: 'step_two',
            templateUrl: 'modules/diary/addcase/step_one.html',
            data: {pageTitle: 'Rate Information'},
            loadPlugin: function ($ocLazyLoad) {
                return $ocLazyLoad.load([
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    }
                ]);
            }
        })
        .state('diary.addcase.step_three', {
            url: 'step_three',
            templateUrl: 'modules/diary/addcase/step_three.html',
            data: {pageTitle: 'Other Information'},
            loadPlugin: function ($ocLazyLoad) {
                return $ocLazyLoad.load([
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    }
                ]);
            },
        })
        .state('diary.addcase.step_four', {
            url: 'step_four',
            templateUrl: 'modules/diary/addcase/step_four.html',
            data: {pageTitle: 'Other Firm Information'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        }
                    ]);
                }
            }
        })
        .state('diary.editcase', {
            url: '/',
            templateUrl: "modules/diary/addcase/addcase.html",
            data: {pageTitle: 'Edit Diary'},
            controller: 'editcaseController',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        },
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        },
                        {
                            name: 'ui.toggle',
                            files: ['css/plugins/toggle-button/angular-bootstrap-toggle.min.css','js/plugins/toggle-button/angular-bootstrap-toggle.min.js']
                        }
                    ]);
                },
                checkUserPermission: checkUserPermission('diary', 'edit'),
                JOB_NO: function () {
                    return null;
                }
            }
        })
        .state('diary.editcase.step_one', {
            url: 'step_one/:id',
            templateUrl: 'modules/diary/addcase/step_two.html',
            data: {pageTitle: 'Firm Information'},
            resolve:{
                loadPlugin:function($ocLazyLoad){
                    return $ocLazyLoad.load([
                        {
                            name: 'ngPatternRestrict',
                            files: ['js/plugins/ng-pattern-restrict/ng-pattern-restrict.min.js']
                        },{
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    }]);
                }
            }
        })
        .state('diary.editcase.step_two', {
            url: 'step_two/:id',
            templateUrl: 'modules/diary/addcase/step_one.html',
            data: {pageTitle: 'General Information'},
        })
        .state('diary.editcase.step_three', {
            url: 'step_three/:id',
            templateUrl: 'modules/diary/addcase/step_three.html',
            data: {pageTitle: 'Other Information'},
            loadPlugin: function ($ocLazyLoad) {
                return $ocLazyLoad.load([
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    }
                ]);
            },
        })
        .state('diary.editcase.step_four', {
            url: 'step_four/:id',
            templateUrl: 'modules/diary/addcase/step_four.html',
            data: {pageTitle: 'Other Firm Information'},
        })
        .state('diary.editcase.step_five', {
            url: 'step_five/:id',
            templateUrl: 'modules/diary/addcase/step_five.html',
            data: {pageTitle: 'Witness Information'},
        })
        .state('diary.editcase.step_six', {
            url: 'step_six/:id',
            templateUrl: 'modules/diary/addcase/step_six.html',
            data: {pageTitle: 'Post Entry'},
            loadPlugin: function ($ocLazyLoad) {
                return $ocLazyLoad.load([
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    }
                ]);
            },
        })
        .state('diary.editcase.commission', {
            url: "commission/:id",
            templateUrl: "modules/diary/addcase/commission.html",
            data: {pageTitle: 'Calendar Commission'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen-add-option.js', 'js/plugins/chosen/chosen.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('attorney_firm', 'manage_commission')
            }
        })
        .state('diary.editcase.documents', {
            url: "documents/:id",
            templateUrl: "modules/diary/addcase/documents.html",
            data: {pageTitle: 'Calendar Documents'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen-add-option.js', 'js/plugins/chosen/chosen.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('diary', 'edit')
            }
        })
        .state('diary.editcase.notes', {
            url: "notes/:id",
            templateUrl: "modules/diary/addcase/notes.html",
            data: {pageTitle: 'Calendar Notes'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen-add-option.js', 'js/plugins/chosen/chosen.js']
                    },{
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }]);
                },
                checkUserPermission: checkUserPermission('diary', 'edit')
            }
        })
        //Payroll Module Start
        .state('payroll', {
            abstract: true,
            url: "/payment",
            templateUrl: "views/common/content.html"
        })
        .state('payroll.add_payment', {
            url: "/add?job_id",
            templateUrl: "modules/payroll/find.html",
            controller: 'payrollController',
            data: {pageTitle: 'Payroll Operation'},
            resolve: {
                checkUserPermission: checkUserPermission('payroll', 'create'),
                $uibModalInstance: function () {
                    return {};
                },
            }
        })
        .state('payroll.resourceslist', {
            url: '/resourceslist/:job_no',
            templateUrl: "modules/payroll/resourceslist.html",
            data: {pageTitle: 'Payroll Operation'},
            controller: 'paryrollResourceListController',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        },
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        }
                    ]);
                },
                checkUserPermission: checkUserPermission('payroll', 'create'),
                JOB_NO: function () {
                    return null;
                }
            }
        })
        .state('payroll.edit', {
            url: "/edit/:id",
            templateUrl: "modules/payroll/payment.html",
            // controller: 'editpayrollController',
            controller: 'newEditpayrollController',
            data: {pageTitle: 'Payroll Operation'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        },
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        }
                    ]);
                },
                checkUserPermission: checkUserPermission('payroll', 'edit'),
                JOB_NO : function(){
                    return null;
                },
                $uibModalInstance: function () {
                    return {};
                }
            }
        })
        .state('payroll.view', {
            url: "/view/:id",
            templateUrl: "modules/payroll/payment_view.html",
            controller: 'viewPayrollController',
            data: {pageTitle: 'Payroll Operation'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }
                    ]);
                },
                checkUserPermission: checkUserPermission('payroll', 'view'),
                $uibModalInstance: function () {
                    return {};
                }
            }
        })
        .state('payroll.adjustment', {
            url: "/adjustment",
            templateUrl: "modules/payroll/adjustment.html",
            controller: 'adjustmentController',
            data: {pageTitle: 'Payroll Adjustment With Job No'},
            resolve: {
                checkUserPermission: checkUserPermission('adjustment', 'create')
            }
        })
        .state('payroll.add_adjustment', {
            url: "/adjustment/:id",
            templateUrl: "modules/payroll/add_adjustment.html",
            controller: 'addadjustmentController',
            data: {pageTitle: 'Payroll Adjustment With Job No'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }, {
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('adjustment', 'create')
            }
        })
        .state('payroll.checkOperations', {
            url: "/checkOperations",
            templateUrl: "modules/payroll/checkOperations.html",
            controller: 'checkOperationController',
            data: {pageTitle: 'Check Operations'},
            resolve: {
                    checkUserPermission: checkUserPermission('payroll', 'check.operations'),
                    loadPlugin: function ($ocLazyLoad) {
                        return $ocLazyLoad.load([{
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        },{
                            serie: true,
                            name: 'datatables',
                            files: ['js/plugins/dataTables/angular-datatables.min.js']
                        }]);
                    }
            }
        })
        .state('payroll.invoicedNotPaidReporters', {
            url: "/invoiced-not-paid-reporters",
            templateUrl: "modules/payroll/invoicedNotPaidReporters.html",
            controller: 'invoicedNotPaidReportersController',
            data: {pageTitle: 'Invoiced But Not Paid Resources'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    }, 
                    {
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                    {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    }
                    ]);
                },
                checkUserPermission: checkUserPermission('payroll', 'view.invoiced.but.not.paid.reporter')

            }
        })
        .state('payroll.downloadedNotPaidReporters', {
            url: "/downloaded-not-paid-reporters",
            templateUrl: "modules/payroll/downloadedNotPaidReporters.html",
            controller: 'downloadedNotPaidReportersController',
            data: {pageTitle: 'Downloaded But Not Paid Resources'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    }, 
                    {
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                    {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    }
                    ]);
                },
                checkUserPermission: checkUserPermission('payroll', 'view.downloaded.but.not.paid.reporter')

            }
        })
        .state('payroll.downloadedNoWitnessAdded', {
            url: "/downloaded-no-witness-added",
            templateUrl: "modules/payroll/downloadedNoWitnessAdded.html",
            controller: 'downloadedNoWitnessAddedController',
            data: {pageTitle: 'Downloaded But No Witness Added'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    }, 
                    {
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                    {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    }
                    ]);
                },
                checkUserPermission: checkUserPermission('payroll', 'view.downloaded.but.no.witness.added')

            }
        })
        .state('payroll.paidReportersButNotInvoiced', {
            url: "/paid-reporters-but-not-invoiced",
            templateUrl: "modules/payroll/paidReportersButNotInvoiced.html",
            controller: 'paidReportersButNotInvoicedController',
            data: {pageTitle: 'Paid Resources But Not Invoiced'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, 
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                    {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    }
                    ]);
                },
                checkUserPermission: checkUserPermission('payroll', 'view.paid.reporter.but.not.invoiced')

            }
        })
        .state('payroll.reporter_payment_not_setup', {
            url: "/payment-date-not-setup",
            templateUrl: "modules/payroll/jobReporterPaymentNotSetup.html",
            controller: 'jobReporterPaymentNotSetupController',
            data: {pageTitle: 'Job list for resources payment not setup'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        },
                        {
                            insertBefore: '#loadBefore',
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        },
                        {
                            serie: true,
                            name: 'ngTagsInput',
                            files: ['js/plugins/ng-tags-input/ng-tags-input.min.js']
                        }
                    ]);
                },
                checkUserPermission: checkUserPermission('payroll', 'view.payment.not.setup')

            }
        })
        .state('payroll.adjustmentHasNoJob', {
            url: "/adjustmentHasNoJob",
            templateUrl: "modules/payroll/adjustmentHasNoJob.html",
            controller: 'adjustmentHasNoJobController',
            data: {pageTitle: 'Payroll Adjustment Without Job No'},
            resolve: {
                checkUserPermission: checkUserPermission('adjustment', 'create')
            }
        })
        .state('payroll.check_replacement', {
            url: "/check-replacement",
            templateUrl: "modules/payroll/check_replacement/checkReplacement.html",
            controller: 'checkReplacementController',
            data: {pageTitle: 'Check Replacement'},
            resolve: {
                checkUserPermission: checkUserPermission('payroll', 'check.replacement')
            }
        })
        .state('payroll.check_replacement.step_one', {
            url: '/step_one/:id',
            templateUrl: 'modules/payroll/check_replacement/step_one.html'
        })
        .state('payroll.check_replacement.step_two', {
            url: '/step_two/:check_number',
            templateUrl: 'modules/payroll/check_replacement/step_two.html'
        })
        .state('payroll.check_replacement.step_three', {
            url: '/step_three/:id',
            templateUrl: 'modules/payroll/check_replacement/step_three.html'
        })

        // Production Module
         .state('production', {
            abstract: true,
            url: "/production",
            templateUrl: "views/common/content.html"
        })
        .state('production.browse_batch', {
            url: "/browse-batch",
            templateUrl: "modules/production/browse_batch.html",
            controller: 'browseBatchController',
            data: {pageTitle: 'Browse Batch'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js','css/plugins/dataTables/datatables.min.css']
                    }, 
                    {
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                    {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('production_metrics', 'view')
            }
        })
        .state('production.browse_batch_assigned', {
            url: "/browse-batch-assigned",
            templateUrl: "modules/production/browse_assigned_batch.html",
            controller: 'browseAssignedBatchController',
            data: {pageTitle: 'Browse Assigned Batch'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js','css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('production_metrics', 'view')
            }
        })
        .state('production.add_batch', {
            url: "/add-batch",
            templateUrl: "modules/production/add_batch.html",
            controller: 'createBatchController',
            data: {pageTitle: 'Add Batch'},
            resolve: {
                checkUserPermission: checkUserPermission('production_metrics', 'create'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },
                    {
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },
                    {
                        insertBefore: '#loadBefore',
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                    {
                        serie: true,
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.min.js']
                    }
                    ]);
                
                }
            }
        })
        .state('production.edit_batch', {
            url: "/edit-batch/:id",
            templateUrl: "modules/production/add_batch.html",
            controller: 'createBatchController',
            data: {pageTitle: 'Edit Batch'},
            resolve: {
                checkUserPermission: checkUserPermission('production_metrics', 'edit'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },
                    {
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },
                    {
                        insertBefore: '#loadBefore',
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                    {
                        serie: true,
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.min.js']
                    }
                    ]);
                
                }
            }
        })

        // Invoice Module
        .state('invoice', {
            abstract: true,
            url: "/invoice",
            templateUrl: "views/common/content.html"
        })
        .state('invoice.find', {
            url: "/find",
            templateUrl: "modules/invoice/findInvoice.html",
            controller: 'findInvoiceController',
            data: {pageTitle: 'Find An Invoice'},
            resolve: {
                checkUserPermission: checkUserPermission('invoice', 'view')
            }
        })
        .state('invoice.browse', {
            url: "/browse?invoice_number",
            templateUrl: "modules/invoice/browse.html",
            controller: 'invoiceController',
            data: {pageTitle: 'invoice'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },
                    {
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },
                    {
                        insertBefore: '#loadBefore',
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                    {
                        serie: true,
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.min.js']
                    }
                    ]);
                },
                checkUserPermission: checkUserPermission('invoice', 'view')
            }
        })
        .state('invoice.browse_data', {
            url: "/browse-data?invoice_number",
            templateUrl: "modules/invoice/browse_data.html",
            controller: 'invoiceControllerData',
            data: {pageTitle: 'invoice'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },
                    {
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },
                    {
                        insertBefore: '#loadBefore',
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                    {
                        serie: true,
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.min.js']
                    }
                    ]);
                },
                checkUserPermission: checkUserPermission('invoice', 'view')
            }
        }) 
        .state('invoice.browse_geico_invoice', {
            url: "/geico-invoice/browse",
            // url: "/browse",
            templateUrl: "modules/invoice/geico_invoice/browse.html",
            controller: 'geicoInvoiceController',  
            data: {pageTitle: 'invoice'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },
                    {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    },
                    {
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },
                    ,{
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    },
                    {
                        insertBefore: '#loadBefore',
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                    {
                        serie: true,
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.min.js']
                    }
                    ]);
                },
                checkUserPermission: checkUserPermission('invoice', 'view')
            }
        }) 
        .state('invoice.browse_amtrust_invoice', {
            url: "/amtrust-invoice/browse",
            // url: "/browse",
            templateUrl: "modules/invoice/amtrust_invoice/browse.html",
            controller: 'amtrustInvoiceController',  
            data: {pageTitle: 'invoice'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },
                    {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    },
                    {
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },
                    ,{
                        serie: true,
                        files: ['js/plugins/daterangepicker/daterangepicker.js', 'css/plugins/daterangepicker/daterangepicker-bs3.css']
                    },{
                        name: 'daterangepicker',
                        files: ['js/plugins/daterangepicker/angular-daterangepicker.js']
                    },
                    {
                        insertBefore: '#loadBefore',
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                    {
                        serie: true,
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.min.js']
                    }
                    ]);
                },
                checkUserPermission: checkUserPermission('invoice', 'view')
            }
        }) 
        .state('invoice.create', {
            url: "/create?job_id&holdreview",
            templateUrl: "modules/invoice/create_invoice.html",
            controller: 'createInvoiceController',
            data: {pageTitle: 'Add Invoice'},
            resolve: {
                checkUserPermission: checkUserPermission('invoice', 'create'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        },
                        {
                            insertBefore: '#loadBefore',
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        },
                    ]);
                }
            }
        })
        .state('invoice.edit', {
            url: "/edit/:invoice_id",
            templateUrl: "modules/invoice/create_invoice.html",
            controller: 'editInvoiceController',
            data: {pageTitle: 'Edit Invoice'},
            resolve: {
                checkUserPermission: checkUserPermission('invoice', 'edit'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        },
                        {
                            insertBefore: '#loadBefore',
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        },
                    ]);
                }
            }
        })
        .state('invoice.cod', {
            url: "/cod-invoices",
            templateUrl: "modules/invoice/cod_invoices.html",
            controller: 'codInvoicesController',
            data: {pageTitle: 'Cod Invoices'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },
                    {
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },
                    {
                        insertBefore: '#loadBefore',
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                    {
                        serie: true,
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('invoice', 'view.cod.invoices')
            }
        })
        .state('invoice.voided', {
            url: "/voided_invoices",
            templateUrl: "modules/invoice/voided_invoices.html",
            controller: 'voidedInvoicesController',
            data: {pageTitle: 'Voided Invoices'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },
                    {
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },
                    {
                        insertBefore: '#loadBefore',
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                    {
                        serie: true,
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('invoice', 'view.voided.invoices')
            }
        })
        .state('invoice.cloned_or_voided', {
            url: "/cloned_or_voided_invoices",
            templateUrl: "modules/invoice/cloned_or_voided_invoices.html",
            controller: 'clonedOrVoidedInvoicesController',
            data: {pageTitle: 'Cloned/Voided Invoices'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },
                    {
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },
                    {
                        insertBefore: '#loadBefore',
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                    {
                        serie: true,
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('invoice', 'view')
            }
        })
        .state('invoice.change_firm', {
            url: "/change-firm/",
            templateUrl: "modules/invoice/change_invoice_firm.html",
            controller: 'changeInvoiceFirmController',
            data: {pageTitle: 'Change Invoice Firm'},
            resolve: {
                checkUserPermission: checkUserPermission('invoice', 'change.invoice.firm'),
            }
        })

        // Cash Receipt
        .state('cash_receipt', {
            abstract: true,
            url: "/cash-receipt",
            templateUrl: "views/common/content.html"
        })
        .state('cash_receipt.find', {
            url: "/find",
            templateUrl: "modules/cash_receipt/find.html",
            controller: 'findCashReceiptController',
            data: {pageTitle: 'Find Cash Receipt'},
            resolve: {
                checkUserPermission: checkUserPermission('cash_receipt', 'view'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }
                    ]);
                }
            }
        })
        .state('cash_receipt.browse', {
            url: "/browse",
            templateUrl: "modules/cash_receipt/browse.html",
            controller: 'cashReceiptController',
            data: {pageTitle: 'Cash Receipt'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    },
                    {
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    }
                    ]);
                },
                checkUserPermission: checkUserPermission('cash_receipt', 'view')
            }
        })
        .state('cash_receipt.singleInvoicePayment', {
            url: "/single-invoice-payment/:inv_no",
            templateUrl: "modules/cash_receipt/single_invoice_payment.html",
            controller: 'singleInvoicePaymentController',
            data: {pageTitle: 'Single Invoice Payment'},
            resolve: {
                checkUserPermission: checkUserPermission('cash_receipt', 'edit'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },
                    {
                        serie: true,
                        name: 'angular-ladda',
                        files: ['js/plugins/ladda/spin.min.js', 'js/plugins/ladda/ladda.min.js', 'css/plugins/ladda/ladda-themeless.min.css', 'js/plugins/ladda/angular-ladda.min.js']
                    },
                    {
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }
                    ]);
                }
            }
        })
        .state('cash_receipt.add', {
            url: "/add",
            templateUrl: "modules/cash_receipt/single_invoice_payment.html",
            controller: 'singleInvoicePaymentController',
            data: {pageTitle: 'Single Invoice Payment'},
            resolve: {
                checkUserPermission: checkUserPermission('cash_receipt', 'single.payment'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    },
                    {
                        serie: true,
                        name: 'angular-ladda',
                        files: ['js/plugins/ladda/spin.min.js', 'js/plugins/ladda/ladda.min.js', 'css/plugins/ladda/ladda-themeless.min.css', 'js/plugins/ladda/angular-ladda.min.js']
                    },
                    {
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }
                    ]);
                }
            }
        })
        .state('cash_receipt.batch_payment', {
            url: "/batchPayment?type",
            templateUrl: "modules/cash_receipt/batch_invoice_payment.html",
            controller: 'batchInvoicePaymentController',
            data: {pageTitle: 'Batch Invoice Payment'},
            resolve: {
                checkUserPermission: checkUserPermission('cash_receipt', 'batch.payment'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                            serie: true,
                            files: ['js/plugins/dataTables/datatables.min.js', 'css/plugins/dataTables/datatables.min.css','css/plugins/ng-tags-input/ng-tags-input.css','css/plugins/ng-tags-input/ng-tags-input.bootstrap.css']
                        }, {
                            serie: true,
                            name: 'datatables',
                            files: ['js/plugins/dataTables/angular-datatables.min.js']
                        }, {
                            serie: true,
                            name: 'datatables.light-columnfilter',
                            files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                        },
                        {
                            serie: true,
                            name: 'angular-ladda',
                            files: ['js/plugins/ladda/spin.min.js', 'js/plugins/ladda/ladda.min.js', 'css/plugins/ladda/ladda-themeless.min.css', 'js/plugins/ladda/angular-ladda.min.js']
                        },
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }
                    ]);
                }
            }
        })
        .state('cash_receipt.auto_invoice_payment', {
            url: "/auto-invoice-payment",
            templateUrl: "modules/cash_receipt/auto_invoice_payment.html",
            controller: 'autoInvoicePaymentController',
            data: {pageTitle: 'Auto Invoice Payment'},
            resolve: {
                checkUserPermission: checkUserPermission('cash_receipt', 'do.auto.invoice.payment')
            }
        })

        /* Insurance Companies */
        .state('insurance', {
            abstract: true,
            url: "/insurance",
            templateUrl: "views/common/content.html"
        })
        .state('insurance.claim_rep_browse', {
            url: "/claim_rep/browse",
            templateUrl: "modules/insurance/claim_rep/browse/browse.html",
            controller: 'claimrepController',
            data: {pageTitle: 'Claim Representative'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('insurance_companies', 'view')
            }
        })
        .state('insurance.claim_rep_add', {
            url: "/claim_rep/add",
            templateUrl: "modules/insurance/claim_rep/add/add.html",
            controller: 'addClaimrepController',
            data: {pageTitle: 'Add Claim Representative'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'ngPatternRestrict',
                            files: ['js/plugins/ng-pattern-restrict/ng-pattern-restrict.min.js']
                        },{

                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    
                    }]);
                },
                checkUserPermission: checkUserPermission('insurance_companies', 'create'),
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                }
            }
        })
        .state('insurance.claim_rep_edit', {
            url: "/claim_rep/edit/:id",
            templateUrl: "modules/insurance/claim_rep/add/add.html",
            controller: 'editClaimrepController',
            data: {pageTitle: 'Edit Claim Representative'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'ngPatternRestrict',
                            files: ['js/plugins/ng-pattern-restrict/ng-pattern-restrict.min.js']
                        },{

                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    
                    }]);
                },
                checkUserPermission: checkUserPermission('insurance_companies', 'edit')
            }
        })

        /* Claim representatives*/
        .state('insurance.claim_rep_name_browse', {
            url: "/claim_rep_name/browse/:id",
            templateUrl: "modules/insurance/claim_rep_name/browse/browse.html",
            controller: 'claimrepnameController',
            data: {pageTitle: 'Claim Representative Name'},
            resolve: {

                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    },{
                        serie: true,
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('claim_representatives', 'view')
            }
        })
        .state('insurance.claim_rep_name_add', {
            url: "/claim_rep_name/add",
            templateUrl: "modules/insurance/claim_rep_name/add/add.html",
            controller: 'addClaimrepnameController',
            data: {pageTitle: 'Add Claim Representative Name'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                    {
                        name: 'ngPatternRestrict',
                        files: ['js/plugins/ng-pattern-restrict/ng-pattern-restrict.min.js']
                    },{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('claim_representatives', 'create'),
                claim_rep_data: function () {
                    return null;
                },
                $uibModalInstance: function () {
                    return {};
                },
                fromModal :function(){
                    return false;
                },
            }
        })
        .state('insurance.claim_rep_name_edit', {
            url: "/claim_rep_name/edit/:id",
            templateUrl: "modules/insurance/claim_rep_name/add/add.html",
            controller: 'editClaimrepnameController',
            data: {pageTitle: 'Edit Claim Representative Name'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                    {
                        name: 'ngPatternRestrict',
                        files: ['js/plugins/ng-pattern-restrict/ng-pattern-restrict.min.js']
                    },{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('claim_representatives', 'edit'),
                claim_rep_data: function () {
                    return null;
                },
                $uibModalInstance: function () {
                    return {};
                },
                fromModal : function(){
                    return false;
                },
                claimrepId : function(){
                    return null;
                },
            }
        })
        .state('insurance.merge_insurance_company', {
            url: "/merge-insurance-company",
            templateUrl: "modules/insurance/claim_rep/mergeInsuranceCompany/merge_insurance_company.html",
            controller: 'mergeInsuranceCompanyController',
            data: {pageTitle: 'Merge Insurance Company'},
            resolve:{
                checkUserPermission: checkUserPermission('insurance_companies', 'merge'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },{
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    }]);
                },
            }
        }).state('insurance.merge_claim_rep_name', {
            url: "/merge-claim-representative",
            templateUrl: "modules/insurance/claim_rep/mergeClaimRep/merge_claim_rep.html",
            controller: 'mergeClaimRepController',
            data: {pageTitle: 'Merge Claim Representatives'},
            resolve:{
                checkUserPermission: checkUserPermission('claim_representatives', 'merge'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },{
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    }]);
                },
            }
        })


        // Role Module
        .state('roles', {
            abstract: true,
            url: "/roles",
            templateUrl: "views/common/content.html"
        })
        .state('roles.browse', {
            url: "/browse",
            templateUrl: "modules/roles/browse.html",
            controller: 'rolesController',
            data: {pageTitle: 'Lexitas User Roles'},
            resolve: {
                checkUserPermission: checkUserPermission('roles', 'view'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                }
            }
        })
        .state('roles.create', {
            url: "/create",
            templateUrl: "modules/roles/create_role.html",
            controller: 'createRolesController',
            data: {pageTitle: 'Create Role'},
            resolve: {
                checkUserPermission: checkUserPermission('roles', 'create'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['css/plugins/iCheck/custom.css', 'js/plugins/iCheck/icheck.min.js']
                        }
                    ]);
                }
            }
        })
        .state('roles.edit', {
            url: "/edit/:id",
            templateUrl: "modules/roles/create_role.html",
            controller: 'editRolesController',
            data: {pageTitle: 'Edit Role'},
            resolve: {
                checkUserPermission: checkUserPermission('roles', 'edit'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            serie: true,
                            name: 'angular-ladda',
                            files: ['js/plugins/ladda/spin.min.js', 'js/plugins/ladda/ladda.min.js', 'css/plugins/ladda/ladda-themeless.min.css', 'js/plugins/ladda/angular-ladda.min.js']
                        }
                    ]);
                }
            }
        })

        // archive module
        .state('archive',{
            abstract: true,
            url:"/archive",
            templateUrl : "views/common/content.html"
        })
        .state('archive.browse',{
            url: "/browse",
            templateUrl :"modules/archive/browse.html",
            controller: 'archiveController',
            data : {pageTitle : 'Archive'},
            resolve: {
                checkUserPermission : checkUserPermission('reports', 'view.summary.billing.by.firm'),
            loadPlugin : function ($ocLazyLoad){
                return $ocLazyLoad.load([{
                    files: ['js/plugins/footable/footable.all.min.js', 'css/plugins/footable/footable.core.css']
                },{
                    name: 'ui.footable',
                    files: ['js/plugins/footable/angular-footable.js']
                },{
                    name: 'localytics.directives',
                    files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                },{
                    name: 'ngTagsInput',
                    files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                },{
                    files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                }]);
            }
          }
        })

        // User Module
        .state('users', {
            abstract: true,
            url: "/users",
            templateUrl: "views/common/content.html"
        })
        .state('users.browse', {
            url: "/browse",
            templateUrl: "modules/users/browse.html",
            controller: 'usersController',
            data: {pageTitle: 'Lexitas Users'},
            resolve: {
                checkUserPermission: checkUserPermission('users', 'view'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js','css/plugins/dataTables/datatables.min.css']
                    },{
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    },{
                        serie: true,
                        name: 'angular-ladda',
                        files: ['js/plugins/ladda/spin.min.js', 'js/plugins/ladda/ladda.min.js', 'css/plugins/ladda/ladda-themeless.min.css', 'js/plugins/ladda/angular-ladda.min.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    },{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    }]);
                } 
            }
        })      
        .state('users.create', {
            url: "/create",
            templateUrl: "modules/users/create_user.html",
            controller: 'createUserController',
            data: {pageTitle: 'Create User'},
            resolve: {
                checkUserPermission: checkUserPermission('users', 'create'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name:'dndLists',
                            files: [ 'js/plugins/angular-dragdrop/simple.css','js/plugins/angular-dragdrop/angular-drag-and-drop-lists.min.js']
                        },
                        {
                        files: ['css/plugins/iCheck/custom.css', 'js/plugins/iCheck/icheck.min.js']
                        },
                       {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        }
                    ]);
                }
            }
        })
        .state('users.edit', {
            url: "/edit/:id",
            templateUrl: "modules/users/create_user.html",
            controller: 'editUserController',
            data: {pageTitle: 'Edit User'},
            resolve: {
                checkUserPermission: checkUserPermission('users', 'edit'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            serie: true,
                            name: 'angular-ladda',
                            files: ['js/plugins/ladda/spin.min.js', 'js/plugins/ladda/ladda.min.js', 'css/plugins/ladda/ladda-themeless.min.css', 'js/plugins/ladda/angular-ladda.min.js']
                        },
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        },
                        {
                            name:'dndLists',
                            files: [ 'js/plugins/angular-dragdrop/simple.css','js/plugins/angular-dragdrop/angular-drag-and-drop-lists.min.js']
                        },
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }
                    ]);
                }
            }
        })

        .state('code_tables', {
            abstract: true,
            url: "/master",
            templateUrl: "views/common/content.html"
        })

        .state('rate_tables', {
            abstract: true,
            url: "/master_rates",
            templateUrl: "views/common/content.html"
        })

        /* Additional Charges*/
        .state('rate_tables.additional_charges_browse', {
            url: "/additional-charges/browse",
            templateUrl: "modules/code_tables/additional_charges/browse/browse.html",
            controller: 'additionalChargesController',
            data: {pageTitle: 'Additional Charges'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    },
                    {
                        serie: true,
                        name: 'angular-ladda',
                        files: ['js/plugins/ladda/spin.min.js', 'js/plugins/ladda/ladda.min.js', 'css/plugins/ladda/ladda-themeless.min.css', 'js/plugins/ladda/angular-ladda.min.js']
                    },
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                    {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('additional_charges', 'view')
            }
        })
        .state('rate_tables.additional_charges_add', {
            url: "/additional-charges/add",
            templateUrl: "modules/code_tables/additional_charges/add/add.html",
            controller: 'addadditionalChargesController',
            data: {pageTitle: 'Add Additional Charge'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        }]);
                },
                checkUserPermission: checkUserPermission('additional_charges', 'create')
            }
        })
        .state('rate_tables.additional_charges_edit', {
            url: "/additional-charges/edit/:id",
            templateUrl: "modules/code_tables/additional_charges/add/add.html",
            controller: 'editadditionalChargesController',
            data: {pageTitle: 'Edit Additional Charge'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        }]);
                },
                checkUserPermission: checkUserPermission('additional_charges', 'edit')
            }
        })
        .state('rate_tables.additional_charges_merge', {
            url: "/additional-charges/merge-log",
            templateUrl: "modules/code_tables/additional_charges/browse/mergelog.html",
            controller: 'additionalChargesMergeController',
            data: {pageTitle: 'Additional Charges Merge Log'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    },
                    {
                        serie: true,
                        name: 'angular-ladda',
                        files: ['js/plugins/ladda/spin.min.js', 'js/plugins/ladda/ladda.min.js', 'css/plugins/ladda/ladda-themeless.min.css', 'js/plugins/ladda/angular-ladda.min.js']
                    },
                    {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('additional_charges', 'view.merge.log')
            }
        })

        /* Additional Charges Group */
        .state('rate_tables.additional_charges_group_browse', {
            url: "/additional-charges-group/browse",
            templateUrl: "modules/code_tables/additional_charges_group/browse/browse.html",
            controller: 'additionalChargesGroupController',
            data: {pageTitle: 'Additional Charges Group'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }, {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }]);
                },
                checkUserPermission: checkUserPermission('additional_charges_group', 'view')
            }
        })
        .state('rate_tables.additional_charges_group_add', {
            url: "/additional-charges-group/add",
            templateUrl: "modules/code_tables/additional_charges_group/add/add.html",
            controller: 'addAdditionalChargesGroupController',
            data: {pageTitle: 'Add Additional Charges Group'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }
                    ]);
                },
                fromModal: function () {
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                checkUserPermission: checkUserPermission('additional_charges_group', 'create')
            }
        })
        .state('rate_tables.additional_charges_group_edit', {
            url: "/additional-charges-group/edit/:id",
            templateUrl: "modules/code_tables/additional_charges_group/add/add.html",
            controller: 'editAdditionalChargesGroupController',
            data: {pageTitle: 'Edit Additional Charges Group'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }
                    ]);
                },
                fromModal: function () {
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                additionalChargesGroupId: function () {
                    return null;
                },
                checkUserPermission: checkUserPermission('additional_charges_group', 'edit')
            }
        })

        /* Rate Card Group*/
        .state('rate_tables.rate_card_group_browse', {
            url: "/rate-card-group/browse",
            templateUrl: "modules/code_tables/rate_card_group/browse/browse.html",
            controller: 'ratecardgroupController',
            data: {pageTitle: 'Rate Card Group'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    },{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }]);
                },
                checkUserPermission: checkUserPermission('rate_card_group', 'view')
            }
        })
        .state('rate_tables.rate_card_group_add', {
            url: "/rate-card-group/add",
            templateUrl: "modules/code_tables/rate_card_group/add/add.html",
            controller: 'addratecardgroupController',
            data: {pageTitle: 'Add Rate Card Group'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }
                    ]);
                },
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                checkUserPermission: checkUserPermission('rate_card_group', 'create')
            }
        })
        .state('rate_tables.rate_card_group_edit', {
            url: "/rate-card-group/edit/:id",
            templateUrl: "modules/code_tables/rate_card_group/add/add.html",
            controller: 'editratecardgroupController',
            data: {pageTitle: 'Edit Rate Card Group'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }
                    ]);
                },
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                ratecardgroupId : function(){
                    return null;
                },
                checkUserPermission: checkUserPermission('rate_card_group', 'edit')
            }
        })

        .state('rate_tables.rate_details', {
            url: "/rate-details",
            templateUrl: "modules/code_tables/rate_details/rates.html",
            controller: 'rateDetailController',
            data: {pageTitle: 'Regular/Other Rates'},
            resolve: {
                checkUserPermission: checkUserPermission('regular_other_rates', 'view')
            }
        })
        .state('rate_tables.rate_add', {
            url: "/rate-add",
            templateUrl: "modules/code_tables/rate_details/rates.html",
            controller: 'addRateController',
            data: {pageTitle: 'Regular/Other Rates'},
            resolve: {
                checkUserPermission: checkUserPermission('regular_other_rates', 'view')
            }
        })
        .state('rate_tables.rate_list', {
            url: "/rate-list",
            templateUrl: "modules/code_tables/rate_details/browse/browse.html",
            controller: 'listRateController',
            data: {pageTitle: 'List Regular/Other Rates'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('regular_other_rates', 'view')
            }
        })
        .state('rate_tables.rate_details.step_one', {
            url: "/step_one/:id",
            templateUrl: "modules/code_tables/rate_details/step_one.html"
        })
        .state('rate_tables.rate_details.step_two', {
            url: "/step_two/:id",
            templateUrl: "modules/code_tables/rate_details/step_two.html"
        })
        .state('rate_tables.rate_add.step_one', {
            url: "/step_one",
            templateUrl: "modules/code_tables/rate_details/step_one.html"
        })

        /*Rates */
        .state('rates', {
            abstract: true,
            url: "/rates",
            templateUrl: "views/common/content.html"
        })
        .state('rates.browse', {
            url: "/browse",
            templateUrl: "modules/rates/browse.html",
            controller: 'ratesController',
            data: {pageTitle: 'Rates'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            serie: true,
                            files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                        }, {
                            serie: true,
                            name: 'datatables',
                            files: ['js/plugins/dataTables/angular-datatables.min.js']
                        }, {
                            serie: true,
                            name: 'datatables.columnfilter',
                            files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                        },
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        },{
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        },
                        {
                            serie: true,
                            name: 'datatables.buttons',
                            files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                        }]);
                },
                checkUserPermission: checkUserPermission('rates', 'view')
            }
        })
        .state('rates.create', {
            url: "/create",
            templateUrl: "modules/rates/create_rate.html",
            controller: 'createrateController',
            data: {pageTitle: 'Add Rate'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen-add-option.js', 'js/plugins/chosen/chosen.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('rates', 'create')
            }
        })
        .state('rates.edit', {
            url: "/edit/:id",
            templateUrl: "modules/rates/create_rate.html",
            controller: 'editrateController',
            data: {pageTitle: 'Edit Rate'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen-add-option.js', 'js/plugins/chosen/chosen.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('rates', 'edit')
            }
        })

        /* Job Type*/
        .state('code_tables.job_type_browse', {
            url: "/job-type/browse",
            templateUrl: "modules/code_tables/job_type/browse/browse.html",
            controller: 'jobtypeController',
            data: {pageTitle: 'Job Type'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    },{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                    {
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('job_type', 'view')
            }
        })
        .state('code_tables.job_type_add', {
            url: "/job-type/add",
            templateUrl: "modules/code_tables/job_type/add/add.html",
            controller: 'addjobtypeController',
            data: {pageTitle: 'Add Job Type'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }
                    ]);
                },
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                checkUserPermission: checkUserPermission('job_type', 'create')
            }
        })
        .state('code_tables.job_type_edit', {
            url: "/job-type/edit/:id",
            templateUrl: "modules/code_tables/job_type/add/add.html",
            controller: 'editjobtypeController',
            data: {pageTitle: 'Edit Job Type'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }
                    ]);
                },
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                jobtypeId : function(){
                    return null;
                },
                checkUserPermission: checkUserPermission('job_type', 'edit')
            }
        })

        /*Job Category*/
        .state('code_tables.job_category_browse', {
            url: "/job-category/browse",
            templateUrl: "modules/code_tables/job_category/browse/browse.html",
            controller: 'jobcategoryController',
            data: {pageTitle: 'Job Category'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('job_category', 'view')
            }
        })
        .state('code_tables.job_category_add', {
            url: "/job-category/add",
            templateUrl: "modules/code_tables/job_category/add/add.html",
            controller: 'addjobcategoryController',
            data: {pageTitle: 'Add Job Category'},
            resolve: {
                 loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }
                    ]);
                },
                $uibModalInstance: function () {
                    return {};
                },
                fromModal :function(){
                    return false;
                },
                checkUserPermission: checkUserPermission('job_category', 'create')
            }
        })
        .state('code_tables.job_category_edit', {
            url: "/job-category/edit/:id",
            templateUrl: "modules/code_tables/job_category/add/add.html",
            controller: 'editjobcategoryController',
            data: {pageTitle: 'Edit Job Category'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }
                    ]);
                },
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                jobcategoryId : function(){
                    return null;
                },
                checkUserPermission: checkUserPermission('job_category', 'edit')
            }
        })

        /* Firm Type*/
        .state('code_tables.firm_type_browse', {
            url: "/firm-type/browse",
            templateUrl: "modules/code_tables/firm_type/browse/browse.html",
            controller: 'firmtypeController',
            data: {pageTitle: 'Firm Type'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    },{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                    {
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('firm_type', 'view')
            }
        })
        .state('code_tables.firm_type_add', {
            url: "/firm-type/add",
            templateUrl: "modules/code_tables/firm_type/add/add.html",
            controller: 'addfirmtypeController',
            data: {pageTitle: 'Add Firm Type'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }
                    ]);
                },
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                checkUserPermission: checkUserPermission('firm_type', 'create')
            }
        })
        .state('code_tables.firm_type_edit', {
            url: "/firm-type/edit/:id",
            templateUrl: "modules/code_tables/firm_type/add/add.html",
            controller: 'editfirmtypeController',
            data: {pageTitle: 'Edit Firm Type'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }
                    ]);
                },
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                firmtypeId : function(){
                    return null;
                },
                checkUserPermission: checkUserPermission('firm_type', 'edit')
            }
        })

        /* Firm Address Type*/
        .state('code_tables.firm_address_type_browse', {
            url: "/firm-address-type/browse",
            templateUrl: "modules/code_tables/firm_address_type/browse/browse.html",
            controller: 'firmaddresstypeController',
            data: {pageTitle: 'Firm Address Type'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    },{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                    {
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('firm_address_type', 'view')
            }
        })
        .state('code_tables.firm_address_type_add', {
            url: "/firm-address-type/add",
            templateUrl: "modules/code_tables/firm_address_type/add/add.html",
            controller: 'addfirmaddresstypeController',
            data: {pageTitle: 'Add Firm Address Type'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }
                    ]);
                },
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                checkUserPermission: checkUserPermission('firm_address_type', 'create')
            }
        })
        .state('code_tables.firm_address_type_edit', {
            url: "/firm-address-type/edit/:id",
            templateUrl: "modules/code_tables/firm_address_type/add/add.html",
            controller: 'editfirmaddresstypeController',
            data: {pageTitle: 'Edit Firm Address Type'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }
                    ]);
                },
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                firmaddresstypeId : function(){
                    return null;
                },
                checkUserPermission: checkUserPermission('firm_address_type', 'edit')
            }
        })

        /*Case Type*/
        .state('code_tables.case_type_browse', {
            url: "/case-type/browse",
            templateUrl: "modules/code_tables/case_type/browse/browse.html",
            controller: 'casetypeController',
            data: {pageTitle: 'Case Type'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('case_type', 'view')
            }
        })
        .state('code_tables.case_type_add', {
            url: "/case-type/add",
            templateUrl: "modules/code_tables/case_type/add/add.html",
            controller: 'addcasetypeController',
            data: {pageTitle: 'Add Case Type'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }
                    ]);
                },
                $uibModalInstance: function () {
                    return {};
                },
                fromModal :function(){
                    return false;
                },
                checkUserPermission: checkUserPermission('case_type', 'create')
            }
        })
        .state('code_tables.case_type_edit', {
            url: "/case-type/edit/:id",
            templateUrl: "modules/code_tables/case_type/add/add.html",
            controller: 'editcasetypeController',
            data: {pageTitle: 'Edit Case Type'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }
                    ]);
                },
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                casetypeId : function(){
                    return null;
                },
                checkUserPermission: checkUserPermission('case_type', 'edit')
            }
        })



        /* Grades */
        .state('code_tables.grades_browse', {
            url: "/grades/browse",
            templateUrl: "modules/code_tables/grades/browse/browse.html",
            controller: 'gradesController',
            data: {pageTitle: 'Grades'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('grades', 'view')
            }
        })
        .state('code_tables.grades_add', {
            url: "/grades/add",
            templateUrl: "modules/code_tables/grades/add/add.html",
            controller: 'addgradesController',
            data: {pageTitle: 'Add Grades'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                checkUserPermission: checkUserPermission('grades', 'create')

            }
        })
        .state('code_tables.grades_edit', {
            url: "/grades/edit/:id",
            templateUrl: "modules/code_tables/grades/add/add.html",
            controller: 'editgradesController',
            data: {pageTitle: 'Edit Grades'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                plaintiffId : function(){
                    return null;
                },
                checkUserPermission: checkUserPermission('grades', 'edit')
            }
        })

        /* Timezone */
        .state('code_tables.timezones_browse', {
            url: "/timezones/browse",
            templateUrl: "modules/code_tables/timezones/browse/browse.html",
            controller: 'timezonesController',
            data: {pageTitle: 'Timezone'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('timezones', 'view')
            }
        })
        /*.state('code_tables.timezones_add', {
            url: "/timezones/add",
            templateUrl: "modules/code_tables/timezones/add/add.html",
            controller: 'addtimezonesController',
            data: {pageTitle: 'Add Timezone'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                checkUserPermission: checkUserPermission('timezones', 'create')

            }
        })*/
        .state('code_tables.timezones_edit', {
            url: "/timezones/edit/:id",
            templateUrl: "modules/code_tables/timezones/add/add.html",
            controller: 'edittimezonesController',
            data: {pageTitle: 'Edit Timezone'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                plaintiffId : function(){
                    return null;
                },
                checkUserPermission: checkUserPermission('timezones', 'edit')
            }
        })

        /* Euo Firms */
        .state('code_tables.euo_firm_browse', {
            url: "/euo-firm/browse",
            templateUrl: "modules/code_tables/euo_firm/browse/browse.html",
            controller: 'euoFirmController',
            data: {pageTitle: 'Euo Firm'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('display_euo', 'view')
            }
        })
        .state('code_tables.euo_firm_add', {
            url: "/euo-firm/add",
            templateUrl: "modules/code_tables/euo_firm/add/add.html",
            controller: 'addeuoFirmController',
            data: {pageTitle: 'Add Euo Firm'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    }]);
                },
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                checkUserPermission: checkUserPermission('display_euo', 'create')

            }
        })
        .state('code_tables.euo_firm_edit', {
            url: "/euo-firm/edit/:id",
            templateUrl: "modules/code_tables/euo_firm/add/add.html",
            controller: 'editeuoFirmController',
            data: {pageTitle: 'Edit Euo Firm'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    }]);
                },
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                plaintiffId : function(){
                    return null;
                },
                checkUserPermission: checkUserPermission('display_euo', 'edit')
            }
        })

        /* Job Status */
        .state('code_tables.job_status_browse', {
            url: "/job-status/browse",
            templateUrl: "modules/code_tables/job-status/browse/browse.html",
            controller: 'jobStatusController',
            data: {pageTitle: 'Job Status'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('job_status', 'view')
            }
        })
        .state('code_tables.job_status_add', {
            url: "/job-status/add",
            templateUrl: "modules/code_tables/job-status/add/add.html",
            controller: 'addJobStatusController',
            data: {pageTitle: 'Add Job Status'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                }, 
                checkUserPermission: checkUserPermission('job_status', 'create')

            }
        })
        .state('code_tables.job_status_edit', {
            url: "/job-status/edit/:id",
            templateUrl: "modules/code_tables/job-status/add/add.html",
            controller: 'editJobStatusController',
            data: {pageTitle: 'Edit Job Status'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                plaintiffId : function(){
                    return null;
                }, loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        }
                ]);},
                checkUserPermission: checkUserPermission('job_status', 'edit')
            }
        })

        /* Language */
        .state('code_tables.language_browse', {
            url: "/language/browse",
            templateUrl: "modules/code_tables/language/browse/browse.html",
            controller: 'languageController',
            data: {pageTitle: 'Language'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }, {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('language', 'view')
            }
        })
        .state('code_tables.language_add', {
            url: "/language/add",
            templateUrl: "modules/code_tables/language/add/add.html",
            controller: 'addLanguageController',
            data: {pageTitle: 'Add Language'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                }, 
                checkUserPermission: checkUserPermission('language', 'create')

            }
        })
        .state('code_tables.language_edit', {
            url: "/language/edit/:id",
            templateUrl: "modules/code_tables/language/add/add.html",
            controller: 'editLanguageController',
            data: {pageTitle: 'Edit Language'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                plaintiffId : function(){
                    return null;
                }, loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        }
                ]);},
                checkUserPermission: checkUserPermission('language', 'edit')
            }
        })

        /* Suffix */
        .state('code_tables.suffix_browse', {
            url: "/suffix/browse",
            templateUrl: "modules/code_tables/suffix/browse/browse.html",
            controller: 'suffixController',
            data: {pageTitle: 'Suffix'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }, {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('suffix', 'view')
            }
        })
        .state('code_tables.suffix_add', {
            url: "/suffix/add",
            templateUrl: "modules/code_tables/suffix/add/add.html",
            controller: 'addSuffixController',
            data: {pageTitle: 'Add Suffix'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                }, 
                checkUserPermission: checkUserPermission('suffix', 'create')

            }
        })
        .state('code_tables.suffix_edit', {
            url: "/suffix/edit/:id",
            templateUrl: "modules/code_tables/suffix/add/add.html",
            controller: 'editSuffixController',
            data: {pageTitle: 'Edit Suffix'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                plaintiffId : function(){
                    return null;
                }, loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        }
                ]);},
                checkUserPermission: checkUserPermission('suffix', 'edit')
            }
        })

         /* Service Type*/ 
         .state('code_tables.service_type_browse', {
            url: "/service-type/browse",
            templateUrl: "modules/code_tables/service-type/browse/browse.html",
            controller: 'ServiceTypeController',
            data: {pageTitle: 'Service Type'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('witness_type', 'view')
            }
        }) 
    
       
        .state('code_tables.service_type_add', {
            url: "/service-type/add",
            templateUrl: "modules/code_tables/service-type/add/add.html",
            controller: 'addServiceTypeController',
            data: {pageTitle: 'Add Service Type'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        }
                ]);},
                checkUserPermission: checkUserPermission('witness_type', 'create')
            }
        })
        .state('code_tables.service_type_edit', {
            url: "/service-type/edit/:id",
            templateUrl: "modules/code_tables/service-type/add/add.html",
            controller: 'editServiceTypeController',
            data: {pageTitle: 'Edit Service Type'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        }
                ]);},
                checkUserPermission: checkUserPermission('witness_type', 'edit')
            }
        })


         /* Service Status */
         .state('code_tables.service_status_browse', {
            url: "/service-status/browse",
            templateUrl: "modules/code_tables/service-status/browse/browse.html",
            controller: 'serviceStatusController',
            data: {pageTitle: 'Service Status'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('service_status', 'view')
            }
        })
        .state('code_tables.service_status_add', {
            url: "/service-status/add",
            templateUrl: "modules/code_tables/service-status/add/add.html",
            controller: 'addServiceStatusController',
            data: {pageTitle: 'Add Service Status'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        }
                ]);},
                checkUserPermission: checkUserPermission('service_status', 'create')

            }
        })
        .state('code_tables.service_status_edit', {
            url: "/service-status/edit/:id",
            templateUrl: "modules/code_tables/service-status/add/add.html",
            controller: 'editServiceStatusController',
            data: {pageTitle: 'Edit Service Status'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                plaintiffId : function(){
                    return null;
                },loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        }
                ]);},
                checkUserPermission: checkUserPermission('service_status', 'edit')
            }
        })

        /* Witness Types*/
        .state('code_tables.witness_type_browse', {
            url: "/witness-type/browse",
            templateUrl: "modules/code_tables/witness_type/browse/browse.html",
            controller: 'witnesstypeController',
            data: {pageTitle: 'Witness Type'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    },{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('witness_type', 'view')
            }
        })
        .state('code_tables.witness_type_add', {
            url: "/witness-type/add",
            templateUrl: "modules/code_tables/witness_type/add/add.html",
            controller: 'addwitnesstypeController',
            data: {pageTitle: 'Add Witness Type'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                checkUserPermission: checkUserPermission('witness_type', 'create')

            }
        })
        .state('code_tables.witness_type_edit', {
            url: "/witness-type/edit/:id",
            templateUrl: "modules/code_tables/witness_type/add/add.html",
            controller: 'editwitnesstypeController',
            data: {pageTitle: 'Edit Witness Type'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                plaintiffId : function(){
                    return null;
                },
                checkUserPermission: checkUserPermission('witness_type', 'edit')
            }
        })

        
        /* Business Unit*/ 
        .state('code_tables.business_unit_browse', {
            url: "/business-unit/browse",
            templateUrl: "modules/code_tables/business_unit/browse/browse.html",
            controller: 'businessunitController',
            data: {pageTitle: 'Business Unit'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('business_unit', 'view')
            }
        }) 
        .state('code_tables.business_unit_add', {
            url: "/business-unit/add",
            templateUrl: "modules/code_tables/business_unit/add/add.html",
            controller: 'addbusinessunitController',
            data: {pageTitle: 'Add Business Unit'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'ngPatternRestrict',
                        files: ['js/plugins/ng-pattern-restrict/ng-pattern-restrict.min.js']
                    },{
                        files: ['js/plugins/ckeditor/ckeditor-super.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('business_unit', 'create')

            }
        })
        .state('code_tables.business_unit_edit', {
            url: "/business-unit/edit/:id",
            templateUrl: "modules/code_tables/business_unit/add/add.html",
            controller: 'editbusinessunitController',
            data: {pageTitle: 'Edit Business Unit'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                plaintiffId : function(){
                    return null;
                },
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'ngPatternRestrict',
                        files: ['js/plugins/ng-pattern-restrict/ng-pattern-restrict.min.js']
                    },{
                        files: ['js/plugins/ckeditor/ckeditor-super.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('business_unit', 'edit')
            }
        })

        /* Department  start*/
        .state('code_tables.department_browse', {
            url: "/department/browse",
            templateUrl: "modules/code_tables/department/browse/browse.html",
            controller: 'departmentController',
            data: {pageTitle: 'BU Department'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('department', 'view')
            }
        }) 
        .state('code_tables.department_add', {
            url: "/department/add",
            templateUrl: "modules/code_tables/department/add/add.html",
            controller: 'adddepartmentController',
            data: {pageTitle: 'Add BU Department'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'ngPatternRestrict',
                        files: ['js/plugins/ng-pattern-restrict/ng-pattern-restrict.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('department', 'create')

            }
        })
        .state('code_tables.department_edit', {
            url: "/department/edit/:id",
            templateUrl: "modules/code_tables/department/add/add.html",
            controller: 'editdepartmentController',
            data: {pageTitle: 'Edit BU Department'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                plaintiffId : function(){
                    return null;
                },
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        name: 'ngPatternRestrict',
                        files: ['js/plugins/ng-pattern-restrict/ng-pattern-restrict.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('department', 'edit')
            }
        })
        /* Department  end*/


         /* AdditionalInfo*/ 
         .state('code_tables.additionalinfo_browse', {
            url: "/additionalinfo/browse",
            templateUrl: "modules/code_tables/additionalinfo/browse/browse.html",
            controller: 'additionalinfoController',
            data: {pageTitle: 'Additional Info'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('witness_type', 'view')
            }
        }) 
        .state('code_tables.additionalinfo_add', {
            url: "/additionalinfo/add",
            templateUrl: "modules/code_tables/additionalinfo/add/add.html",
            controller: 'addadditionalinfoController',
            data: {pageTitle: 'Add AdditionalInfo'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }
                    ]);
                },
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                checkUserPermission: checkUserPermission('witness_type', 'create')

            }
        })
        .state('code_tables.additionalinfo_edit', {
            url: "/additionalinfo/edit/:id",
            templateUrl: "modules/code_tables/additionalinfo/add/add.html",
            controller: 'editadditionalinfoController',
            data: {pageTitle: 'Edit AdditionalInfo'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }
                    ]);
                },
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                plaintiffId : function(){
                    return null;
                },
                checkUserPermission: checkUserPermission('witness_type', 'edit')
            }
        })

         /* PriorAgency*/ 
         .state('code_tables.prioragency_browse', {
            url: "/prioragency/browse",
            templateUrl: "modules/code_tables/prioragency/browse/browse.html",
            controller: 'prioragencyController',
            data: {pageTitle: 'Prior Agency'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('witness_type', 'view')
            }
        }) 
        .state('code_tables.prioragency_add', {
            url: "/prioragency/add",
            templateUrl: "modules/code_tables/prioragency/add/add.html",
            controller: 'addprioragencyController',
            data: {pageTitle: 'Add PriorAgency'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                checkUserPermission: checkUserPermission('witness_type', 'create')

            }
        })
        .state('code_tables.prioragency_edit', {
            url: "/prioragency/edit/:id",
            templateUrl: "modules/code_tables/prioragency/add/add.html",
            controller: 'editprioragencyController',
            data: {pageTitle: 'Edit PriorAgency'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                plaintiffId : function(){
                    return null;
                },
                checkUserPermission: checkUserPermission('witness_type', 'edit')
            }
        })

         /* RepoterAvailabilityTypes*/ 
         .state('code_tables.repoter_availability_types_browse', {
            url: "/repoter_availability_types/browse",
            templateUrl: "modules/code_tables/repoter_availability_types/browse/browse.html",
            controller: 'repoterAvailabilityTypesController',
            data: {pageTitle: 'Resource Availability Types'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('witness_type', 'view')
            }
        }) 
        .state('code_tables.firm_salesmen_tag_request', {
            url: "/firm_salesmen_tag_request/browse",
            templateUrl: "modules/code_tables/firm_salesmen_tag_request/browse/browse.html",
            controller: 'salesmanTagRequestController',
            data: {pageTitle: 'Account Executive Tag Request'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('witness_type', 'view')
            }
        }) 
        .state('code_tables.repoter_availability_types_add', {
            url: "/repoter_availability_types/add",
            templateUrl: "modules/code_tables/repoter_availability_types/add/add.html",
            controller: 'addRepoterAvailabilityTypesController',
            data: {pageTitle: 'Add Resource Availability Types'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                checkUserPermission: checkUserPermission('witness_type', 'create')

            }
        })
        .state('code_tables.repoter_availability_types_edit', {
            url: "/repoter_availability_types/edit/:id",
            templateUrl: "modules/code_tables/repoter_availability_types/add/add.html",
            controller: 'editRepoterAvailabilityTypesController',
            data: {pageTitle: 'Edit Resource Availability Types'},
            resolve: {
                fromModal : function(){
                    return false; 
                },
                $uibModalInstance: function () {
                    return {};
                },
                plaintiffId : function(){
                    return null;
                },
                checkUserPermission: checkUserPermission('witness_type', 'edit')
            }
        })

        /* Certificate types*/ 
        .state('code_tables.certificate_types_browse', {
            url: "/certificate-types/browse",
            templateUrl: "modules/code_tables/certificate_types/browse/browse.html",
            controller: 'certificateTypesController',
            data: {pageTitle: 'Certificate Types'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('certificate_types', 'view')
            }
        }) 
        .state('code_tables.certificate_types_add', {
            url: "/certificate-types/add",
            templateUrl: "modules/code_tables/certificate_types/add/add.html",
            controller: 'addCertificateTypesController',
            data: {pageTitle: 'Add Certificate Types'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        }
                    ]);
                },
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                checkUserPermission: checkUserPermission('certificate_types', 'create')
            }
        })
        .state('code_tables.certificate_types_edit', {
            url: "/certificate-types/edit/:id",
            templateUrl: "modules/code_tables/certificate_types/add/add.html",
            controller: 'editCertificateTypesController',
            data: {pageTitle: 'Edit Certificate Types'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        }
                    ]);
                },
                fromModal : function(){
                    return false; 
                },
                $uibModalInstance: function () {
                    return {};
                },
                checkUserPermission: checkUserPermission('certificate_types', 'edit')
            }
        })
        
        /*Translator*/
        .state('code_tables.translator_browse', {
            url: "/translator/browse",
            templateUrl: "modules/code_tables/translator/browse/browse.html",
            controller: 'translatorController',
            data: {pageTitle: 'Interpreter'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('translator', 'view')
            }
        })
        .state('code_tables.translator_add', {
            url: "/translator/add",
            templateUrl: "modules/code_tables/translator/add/add.html",
            controller: 'addtranslatorController',
            data: {pageTitle: 'Add Interpreter'},
            resolve: {
                checkUserPermission: checkUserPermission('translator', 'create')
            }
        })
        .state('code_tables.translator_edit', {
            url: "/translator/edit/:id",
            templateUrl: "modules/code_tables/translator/add/add.html",
            controller: 'edittranslatorController',
            data: {pageTitle: 'Edit Interpreter'},
            resolve: {
                checkUserPermission: checkUserPermission('translator', 'edit')
            }
        })

         /* Specialties*/ 
         .state('code_tables.specialties_browse', {
            url: "/specialties/browse",
            templateUrl: "modules/code_tables/specialties/browse/browse.html",
            controller: 'specialtiesController',
            data: {pageTitle: 'Specialties'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        },{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('witness_type', 'view')
            }
        }) 

       
        .state('code_tables.specialties_add', {
            url: "/specialties/add",
            templateUrl: "modules/code_tables/specialties/add/add.html",
            controller: 'addspecialtiesController',
            data: {pageTitle: 'Add Specialties'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        }
                ]);},
                checkUserPermission: checkUserPermission('witness_type', 'create')
            }
        })
        .state('code_tables.specialties_edit', {
            url: "/specialties/edit/:id",
            templateUrl: "modules/code_tables/specialties/add/add.html",
            controller: 'editspecialtiesController',
            data: {pageTitle: 'Edit Specialties'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                venueId : function(){
                    return null;
                },loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        }
                ]);},
                checkUserPermission: checkUserPermission('witness_type', 'edit')
            }
        })

          /* Modality*/ 
          .state('code_tables.modality_browse', {
            url: "/modality/browse",
            templateUrl: "modules/code_tables/modality/browse/browse.html",
            controller: 'modalityController',
            data: {pageTitle: 'Modality'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('witness_type', 'view')
            }
        }) 

       
        .state('code_tables.modality_add', {
            url: "/modality/add",
            templateUrl: "modules/code_tables/modality/add/add.html",
            controller: 'addmodalityController',
            data: {pageTitle: 'Add Modality'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        }
                ]);},
                checkUserPermission: checkUserPermission('witness_type', 'create')
            }
        })
        .state('code_tables.modality_edit', {
            url: "/modality/edit/:id",
            templateUrl: "modules/code_tables/modality/add/add.html",
            controller: 'editmodalityController',
            data: {pageTitle: 'Edit Modality'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([
                        {
                            name: 'localytics.directives',
                            files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                        }
                ]);},
                checkUserPermission: checkUserPermission('witness_type', 'edit')
            }
        })

            /* Capability*/ 
            .state('code_tables.capability_browse', {
                url: "/capability/browse",
                templateUrl: "modules/code_tables/capability/browse/browse.html",
                controller: 'capabilityController',
                data: {pageTitle: 'Capability'},
                resolve: {
                    loadPlugin: function ($ocLazyLoad) {
                        return $ocLazyLoad.load([{
                            serie: true,
                            files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                        }, {
                            serie: true,
                            name: 'datatables',
                            files: ['js/plugins/dataTables/angular-datatables.min.js']
                        }, {
                            serie: true,
                            name: 'datatables.columnfilter',
                            files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                        }, {
                            serie: true,
                            name: 'datatables.buttons',
                            files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                        }]);
                    },
                    checkUserPermission: checkUserPermission('witness_type', 'view')
                }
            }) 
    
           
            .state('code_tables.capability_add', {
                url: "/capability/add",
                templateUrl: "modules/code_tables/capability/add/add.html",
                controller: 'addcapabilityController',
                data: {pageTitle: 'Add Capability'},
                resolve: {
                    fromModal : function(){
                        return false;
                    },
                    $uibModalInstance: function () {
                        return {};
                    },
                    loadPlugin: function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            {
                                name: 'localytics.directives',
                                files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                            }
                    ]);},
                    checkUserPermission: checkUserPermission('witness_type', 'create')
                }
            })
            .state('code_tables.capability_edit', {
                url: "/capability/edit/:id",
                templateUrl: "modules/code_tables/capability/add/add.html",
                controller: 'editcapabilityController',
                data: {pageTitle: 'Edit Capability'},
                resolve: {
                    fromModal : function(){
                        return false;
                    },
                    $uibModalInstance: function () {
                        return {};
                    },
                    loadPlugin: function ($ocLazyLoad) {
                        return $ocLazyLoad.load([
                            {
                                name: 'localytics.directives',
                                files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                            }
                    ]);},
                    venueId : function(){
                        return null;
                    },
                    checkUserPermission: checkUserPermission('witness_type', 'edit')
                }
            })

        /*Locations*/
        .state('code_tables.location_browse', {
            url: "/location/browse",
            templateUrl: "modules/code_tables/locations/browse/browse.html",
            controller: 'locationsController',
            data: {pageTitle: 'Locations'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('locations', 'view')
            }
        })
        .state('code_tables.location_add', {
            url: "/location/add",
            templateUrl: "modules/code_tables/locations/add/add.html",
            controller: 'addlocationController',
            data: {pageTitle: 'Add Location'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                checkUserPermission: checkUserPermission('locations', 'create')
            }
        })
        .state('code_tables.location_edit', {
            url: "/location/edit/:id",
            templateUrl: "modules/code_tables/locations/add/add.html",
            controller: 'editlocationController',
            data: {pageTitle: 'Edit Location'},
            resolve: {
                fromModal : function(){
                    return false;
                },
                $uibModalInstance: function () {
                    return {};
                },
                venueId : function(){
                    return null;
                },
                checkUserPermission: checkUserPermission('locations', 'edit')
            }
        })



        /*Rates*/
        .state('code_tables.rate_codetbl_browse', {
            url: "/rate/browse",
            templateUrl: "modules/code_tables/rate_codetbl/browse/browse.html",
            controller: 'ratecodetblController',
            data: {pageTitle: 'Rate'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('rate', 'view')
            }
        })
        .state('code_tables.rate_codetbl_add', {
            url: "/rate/add",
            templateUrl: "modules/code_tables/rate_codetbl/add/add.html",
            controller: 'addrate_codetblController',
            data: {pageTitle: 'Add Rate'},
            resolve: {
                checkUserPermission: checkUserPermission('rate', 'create')
            }
        })
        .state('code_tables.rate_codetbl_edit', {
            url: "/rate/edit/:id",
            templateUrl: "modules/code_tables/rate_codetbl/add/add.html",
            controller: 'editrate_codetblController',
            data: {pageTitle: 'Edit Rate'},
            resolve: {
                checkUserPermission: checkUserPermission('rate', 'edit')
            }
        })

        /*Our suites*/
        .state('code_tables.suites_browse', {
            url: "/suites/browse",
            templateUrl: "modules/code_tables/suites/browse/browse.html",
            controller: 'suitesController',
            data: {pageTitle: 'Suites'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('our_suites', 'view')
            }
        })
        .state('code_tables.suites_add', {
            url: "/suites/add",
            templateUrl: "modules/code_tables/suites/add/add.html",
            controller: 'addsuitesController',
            data: {pageTitle: 'Add Suite'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                return $ocLazyLoad.load([
                    {
                        name: 'ngPatternRestrict',
                        files: ['js/plugins/ng-pattern-restrict/ng-pattern-restrict.min.js']
                    },{
                    serie: true,
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('our_suites', 'create')
            }
        })
        .state('code_tables.suites_edit', {
            url: "/suites/edit/:id",
            templateUrl: "modules/code_tables/suites/add/add.html",
            controller: 'editsuitesController',
            data: {pageTitle: 'Edit Suite'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                return $ocLazyLoad.load([
                    {
                        name: 'ngPatternRestrict',
                        files: ['js/plugins/ng-pattern-restrict/ng-pattern-restrict.min.js']
                    },{
                    serie: true,
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('our_suites', 'edit')
            }
        })

        /*Witness responses*/
        .state('code_tables.witness_responses_browse', {
            url: "/witness-responses/browse",
            templateUrl: "modules/code_tables/witness_responses/browse/browse.html",
            controller: 'witnessResponseController',
            data: {pageTitle: 'Witness Responses'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    },{
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('witness_responses', 'view')
            }
        })
        .state('code_tables.witness_responses_add', {
            url: "/witness-response/add",
            templateUrl: "modules/code_tables/witness_responses/add/add.html",
            controller: 'addwitnessResponseController',
            data: {pageTitle: 'Add Witness Response'},
            resolve: {
                checkUserPermission: checkUserPermission('witness_responses', 'create')
            }
        })
        .state('code_tables.witness_response_edit', {
            url: "/witness-responses/edit/:id",
            templateUrl: "modules/code_tables/witness_responses/add/add.html",
            controller: 'editWitnessResponseController',
            data: {pageTitle: 'Edit Witness Response'},
            resolve: {
                checkUserPermission: checkUserPermission('witness_responses', 'edit')
            }
        })

        /*Group change responses*/
        .state('code_tables.group_charge_browse', {
            url: "/group-charge/browse",
            templateUrl: "modules/code_tables/group_charge/browse/browse.html",
            controller: 'GroupChargeController',
            data: {pageTitle: 'Group Charges'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('group_charge', 'view')
            }
        })
        .state('code_tables.group_charge_add', {
            url: "/group-charge/add",
            templateUrl: "modules/code_tables/group_charge/add/add.html",
            controller: 'addGroupChargeController',
            data: {pageTitle: 'Add Group Charge'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, 
                    {
                    name: 'localytics.directives',
                    files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                    {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('group_charge', 'create')
            }
        })
        .state('code_tables.group_charge_edit', {
            url: "/group-charge/edit/:id",
            templateUrl: "modules/code_tables/group_charge/add/add.html",
            controller: 'editGroupChargeController',
            data: {pageTitle: 'Edit Group Charge'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, 
                    {
                    name: 'localytics.directives',
                    files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                    {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('group_charge', 'edit')
            }
        })
        
        /*Source*/
        .state('code_tables.source_browse', {
            url: "/source/browse",
            templateUrl: "modules/code_tables/source/browse/browse.html",
            controller: 'sourceController',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js','css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('source', 'view')
            }
        })
        .state('code_tables.source_add', {
            url: "/source/add",
            templateUrl: "modules/code_tables/source/add/add.html",
            controller: 'addSourceController',
            data: {pageTitle: 'Add Source'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }]);
                },
                checkUserPermission: checkUserPermission('source', 'create')
            }
        })
        .state('code_tables.source_edit', {
            url: "/source/edit/:id",
            templateUrl: "modules/code_tables/source/add/add.html",
            controller: 'editSourceController',
            data: {pageTitle: 'Edit Source'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                return $ocLazyLoad.load([{
                    serie: true,
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('source', 'edit')
            }
        })


       


        /* Client Level */

        .state('code_tables.client_level_browse', {
            url: "/client-level/browse",
            templateUrl: "modules/code_tables/client_level/browse/browse.html",
            controller: 'clientLevelController',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js','css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('client_level', 'view')
            }
        })
        .state('code_tables.client_level_add', {
            url: "/client-level/add",
            templateUrl: "modules/code_tables/client_level/add/add.html",
            controller: 'addClientLevelController',
            data: {pageTitle: 'Add Client Level'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }]);
                },
                checkUserPermission: checkUserPermission('client_level', 'create')
            }
        })
        .state('code_tables.client_level_edit', {
            url: "/client-level/edit/:id",
            templateUrl: "modules/code_tables/client_level/add/add.html",
            controller: 'addClientLevelController',
            data: {pageTitle: 'Edit Client Level'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                return $ocLazyLoad.load([{
                    serie: true,
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('client_level', 'edit')
            }
        })
        .state('code_tables.service_level_browse', {
            url: "/service-level/browse",
            templateUrl: "modules/code_tables/service_level/browse/browse.html",
            controller: 'serviceLevelController',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js','css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('service_level', 'view')
            }
        })
        .state('code_tables.service_level_add', {
            url: "/service-level/add",
            templateUrl: "modules/code_tables/service_level/add/add.html",
            controller: 'addServiceLevelController',
            data: {pageTitle: 'Add Service Level'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }]);
                },
                checkUserPermission: checkUserPermission('service_level', 'create')
            }
        })
        .state('code_tables.service_level_edit', {
            url: "/service-level/edit/:id",
            templateUrl: "modules/code_tables/service_level/add/add.html",
            controller: 'addServiceLevelController',
            data: {pageTitle: 'Edit Service Level'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                return $ocLazyLoad.load([{
                    serie: true,
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('service_level', 'edit')
            }
        })        
        
        /*FNCL Static Values*/
        .state('code_tables.financial_values_browse', {
            url: "/financial-values/browse",
            templateUrl: "modules/code_tables/financial_values/browse/browse.html",
            controller: 'browseFinancialValuesController',
            data: {pageTitle: 'Browse Financial Values'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    },
                    {
                        name: 'ngTagsInput',
                        files: ['js/plugins/ng-tags-input/ng-tags-input.js', 'css/plugins/ng-tags-input/ng-tags-input.css']
                    },
                    {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    },
                    {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('financial_values', 'view')
            }
        })
        .state('code_tables.financial_values_add', {
            url: "/financial-values/add",
            templateUrl: "modules/code_tables/financial_values/add/financial_values.html",
            controller: 'financialValuesController',
            data: {pageTitle: 'Add Financial Values'},
            resolve: {
                checkUserPermission: checkUserPermission('financial_values', 'create')
            }
        })

        .state('code_tables.financial_values_edit', {
            url: "/financial-values/edit/:id",
            templateUrl: "modules/code_tables/financial_values/add/financial_values.html",
            controller: 'financialValuesController',
            data: {pageTitle: 'Edit Financial Values'},
            resolve: {
                checkUserPermission: checkUserPermission('financial_values', 'edit')
            }
        })

        /*Terms*/
        .state('code_tables.terms', {
            url: "/terms/browse",
            templateUrl: "modules/code_tables/terms/browse.html",
            controller: 'browseTermsController',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js','css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('terms', 'view')
            }
        })
        .state('code_tables.term_add', {
            url: "/terms/add",
            templateUrl: "modules/code_tables/terms/form.html",
            controller: 'addTermController',
            data: {pageTitle: 'Add Term'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([]);
                },
                checkUserPermission: checkUserPermission('terms', 'create')
            }
        })
        .state('code_tables.term_edit', {
            url: "/term/edit/:id",
            templateUrl: "modules/code_tables/terms/form.html",
            controller: 'addTermController',
            data: {pageTitle: 'Edit Term'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                return $ocLazyLoad.load([{
                    serie: true,
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('terms', 'edit')
            }
        })

        /*Connection Methods*/
        .state('code_tables.connection_methods', {
            url: "/connection_methods/browse",
            templateUrl: "modules/code_tables/connection_methods/browse.html",
            controller: 'browseConnectionMethodsController',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js','css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('connection_methods', 'view')
            }
        })
        .state('code_tables.connection_methods_add', {
            url: "/connection_methods/add",
            templateUrl: "modules/code_tables/connection_methods/form.html",
            controller: 'addConnectionMethodsController',
            data: {pageTitle: 'Add Connection Methods'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([]);
                },
                checkUserPermission: checkUserPermission('connection_methods', 'create')
            }
        })
        .state('code_tables.connection_methods_edit', {
            url: "/connection_methods/edit/:id",
            templateUrl: "modules/code_tables/connection_methods/form.html",
            controller: 'addConnectionMethodsController',
            data: {pageTitle: 'Edit Connection Methods'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                return $ocLazyLoad.load([{
                    serie: true,
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('connection_methods', 'edit')
            }
        })

        /*Collectors*/
        .state('code_tables.collectors', {
            url: "/collectors/browse",
            templateUrl: "modules/code_tables/collectors/browse/browse.html",
            controller: 'browseCollectorsController',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js','css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('collectors', 'view')
            }
        })
        .state('code_tables.collector_add', {
            url: "/collector/add",
            templateUrl: "modules/code_tables/collectors/add/add.html",
            controller: 'addCollectorController',
            data: {pageTitle: 'Add Collector'},
            resolve: {
                checkUserPermission: checkUserPermission('collectors', 'create')
            }
        })
        .state('code_tables.collector_edit', {
            url: "/collector/edit/:id",
            templateUrl: "modules/code_tables/collectors/add/add.html",
            controller: 'addCollectorController',
            data: {pageTitle: 'Edit Collector'},
            resolve: {                
                checkUserPermission: checkUserPermission('collectors', 'edit')
            }
        })
        /*Predefined notes*/
        .state('code_tables.ar_predefined_note', {
            url: "/ar-predefine-note/browse",
            templateUrl: "modules/code_tables/ar_predefined_notes/browse/browse.html",
            controller: 'browseArPredefinedNoteController',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js','css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('ar_predefined_notes', 'view')
            }
        })
        .state('code_tables.ar_predefined_note_add', {
            url: "/ar-predefine-note/add",
            templateUrl: "modules/code_tables/ar_predefined_notes/add/add.html",
            controller: 'addArPredefinedNoteController',
            data: {pageTitle: 'Add A/R Predefined Note'},
            resolve: {
                checkUserPermission: checkUserPermission('ar_predefined_notes', 'create')
            }
        })
        .state('code_tables.ar_predefined_note_edit', {
            url: "/ar-predefine-note/edit/:id",
            templateUrl: "modules/code_tables/ar_predefined_notes/add/add.html",
            controller: 'addArPredefinedNoteController',
            data: {pageTitle: 'Edit A/R Predefined Note'},
            resolve: {                
                checkUserPermission: checkUserPermission('ar_predefined_notes', 'edit')
            }
        })
        /*Invoice & Transcript Handling*/
        .state('code_tables.invoice_and_transcript_handling', {
            url: "/invoice-and-transcript-handling/browse",
            templateUrl: "modules/code_tables/invoice_and_transcript_handling/browse/browse.html",
            controller: 'browseInvTransController',
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js','css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('invoice_and_transcript_handling', 'view')
            }
        })
        .state('code_tables.invoice_and_transcript_handling_add', {
            url: "/invoice_and_transcript_handling/add",
            templateUrl: "modules/code_tables/invoice_and_transcript_handling/add/add.html",
            controller: 'AddInvTransController',
            data: {pageTitle: 'Add Invoice And Transcript Handling Method'},
            resolve: {
                loadPlugin: function($ocLazyLoad){
                    return $ocLazyLoad.load([{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('invoice_and_transcript_handling', 'create')
            }
        })
        .state('code_tables.invoice_and_transcript_handling_edit', {
            url: "/invoice_and_transcript_handling/edit/:id",
            templateUrl: "modules/code_tables/invoice_and_transcript_handling/add/add.html",
            controller: 'AddInvTransController',
            data: {pageTitle: 'Edit Invoice And Transcript Handling Method'},
            resolve: {
                loadPlugin: function($ocLazyLoad){
                    return $ocLazyLoad.load([{
                        files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('invoice_and_transcript_handling', 'edit')
            }
        })

        /* Account Executive*/
        .state('sales_person', {
            abstract: true,
            url: "/master",
            templateUrl: "views/common/content.html"
        })
        .state('sales_person.viewall', {
            url: "/sales-person/viewall",
            templateUrl: "modules/sales_person/view.html",
            controller: 'salesPersonController',
            data: {pageTitle: 'View all Account Executive'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/plugins/dataTables/dataTables.columnFilter.js', 'css/plugins/dataTables/datatables.min.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.columnfilter',
                        files: ['js/plugins/dataTables/angular-datatables.columnfilter.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.buttons',
                        files: ['js/plugins/dataTables/angular-datatables.buttons.min.js']
                    }]);
                },
                checkUserPermission: checkUserPermission('sales_person', 'view')
            }
        })
        .state('sales_person.addnew', {
            url: "/sales-person/addnew",
            templateUrl: "modules/sales_person/add.html",
            // controller: 'salesPersonController',
            data: {pageTitle: 'Add new Account Executive'},
            resolve: {
                checkUserPermission: checkUserPermission('sales_person', 'create')
            }
        })
        .state('sales_person.edit', {
            url: "/sales-person/edit",
            templateUrl: "modules/sales_person/edit.html",
            controller: 'salesPersonController',
            data: {pageTitle: 'Edit Account Executive'},
            resolve: {
                checkUserPermission: checkUserPermission('sales_person', 'edit')
            }
        })
        //Invoicing Module Start
        .state('witness', {
            abstract: true,
            url: "/witness",
            templateUrl: "views/common/content.html"
        })
        .state('witness.find_job', {
            url: "/find_job",
            templateUrl: "modules/witness/findcase.html",
            controller: 'witnessJobController',
            data: {pageTitle: 'Find Job witness '},
            resolve: {
                checkUserPermission: checkUserPermission('diary', 'edit')
            }
        })

        /* UPS History */
        .state('history', {
            abstract: true,
            url: "/history",
            templateUrl: "views/common/content.html"
        })
        .state('history.ups_history_add', {
            url: "/ups_history_add",
            templateUrl: "modules/history/add/add.html",
            controller: 'addUpsHistoryController',
            data: {pageTitle: 'Add UPS History'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }]);
                },
                checkUserPermission: checkUserPermission('shipment_tracking', 'create')
            }
        })
        /*twillio msg */
        .state('index.message',{
            url:"/message/:id",
            templateUrl:"modules/message/message.html",
            controller:'messageController',
            data:{pageTitle:'Messages'},
            resolve: {
               checkUserPermission: checkUserPermission('text_messages', 'view'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['js/plugins/dataTables/datatables.min.js', 'js/push-notifications-cdn.js', 'css/twilio_chat.css']
                    }, {
                        serie: true,
                        name: 'datatables',
                        files: ['js/plugins/dataTables/angular-datatables.min.js']
                    }, {
                        serie: true,
                        name: 'datatables.light-columnfilter',
                        files: ['js/plugins/dataTables/dataTables.lightColumnFilter.js','js/plugins/dataTables/angular-datatables.light-columnfilter.js']
                    }, {
                        name: 'localytics.directives',
                        files: ['css/plugins/chosen/bootstrap-chosen.css', 'js/plugins/chosen/chosen.jquery.js', 'js/plugins/chosen/chosen.js']
                    }
                    
                ]);
                }
            }
        })
        .state('index.sandbox_messages',{
            url:"/sandbox-message",
            templateUrl:"modules/message/sandbox_message.html",
            controller:'sandboxMessageController',
            data:{pageTitle:'Sandbox Messages'},
            resolve: {
               checkUserPermission: checkUserPermission('text_messages', 'view.sandbox.messages'),
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                        files: ['css/twilio_chat.css']
                    }]);
                }
            }
        })

        /*Elaw*/
        .state('elaw', {
            abstract: true,
            url: "/elaw",
            templateUrl: "views/common/content.html"
        })
        .state('elaw.import_case_sheet', {
            url: "/import-case-sheet",
            templateUrl: "modules/elaw/elaw.html",
            controller: 'elawController',
            resolve: {
                checkUserPermission: checkUserPermission('elaw_data', 'import')
            }
        })
        .state('elaw.import_case_status_sheet', {
            url: "/import-case-status-sheet",
            templateUrl: "modules/elaw/elaw_status.html",
            controller: 'elawStatusController',
            resolve: {
                checkUserPermission: checkUserPermission('elaw_data', 'import')
            }
        })

        /* global search*/
        .state('global', {
            abstract: true,
            templateUrl: "views/common/content.html"
        })
        .state('global.search', {
            url: "/global-search?q&type&wildcard&numeric",
            templateUrl: "modules/global_search/search.html",
            controller: 'globalSearchController',
            data: {pageTitle: 'Global Search'},
            resolve: {
                loadPlugin: function ($ocLazyLoad) {
                    return $ocLazyLoad.load([{
                        serie: true,
                            files: ['css/plugins/iCheck/custom-blue.css', 'js/plugins/iCheck/icheck.min.js']
                        }]);
                    },
                checkUserPermission: checkUserPermission('global.search', 'view')
            }
        })

        /*Error templates*/
        .state('404', {
            //parent: 'root',
            templateUrl: "modules/error/404.html",
            data: {pageTitle: '404 Error', specialClass: 'error-page'},
        })
        .state('503', {
            //parent: 'root',
            templateUrl: "modules/error/503.html",
            data: {pageTitle: '503 Error', specialClass: 'error-page'},
        });


    $urlRouterProvider.otherwise(function ($injector, $location) {
        var state = $injector.get('$state');
        state.go('login');
        return $location.path();
    });

    //$urlRouterProvider.otherwise('/login');
}


angular.module('deitz').config(config)
    .run(function (Idle,$state,$rootScope) {
        // $rootScope.$state = $state;
         $rootScope.reporterType = {'R':'Reporter', 'T':'Typist','SC':'Scopist','S':'Account Executive','V':'Videographer'};
         $rootScope.resourceType = {'R':'Reporter', 'T':'Typist','SC':'Scopist','S':'Account Executive','V':'Videographer'};
            Idle.watch();
    })
    .run(function (editableOptions, editableThemes) {
        editableOptions.theme = 'default'; // bootstrap3 theme. Can be also 'bs2', 'default'
        // overwrite submit button template
        editableThemes['default'].submitTpl = '<button type="submit" class="btn btn-primary" title="Submit" aria-label="Submit"><span class="glyphicon glyphicon-ok"></span></button>';
        editableThemes['default'].cancelTpl = '<button type="button" class="btn btn-default" ng-click="$form.$cancel()" title="Cancel" aria-label="Cancel"><span class="glyphicon glyphicon-remove"></span></button>';
    });
;

angular.module('deitz').filter('formatDate', function(dateFilter) {
    var formattedDate = '';
    return function(dt) {
        if(dt){
            //console.log(new Date(dt.split('-').join('/')));
            formattedDate = dateFilter(new Date(dt.split('-').join('/')), 'MM/d/yyyy');
            return formattedDate;
        }
        else{
            return '-';
        }
    }

});

angular.module('deitz').factory('authInterceptor', function ($rootScope, $q, $window, $cookies, $location, $injector) {
    var inflightAuthRequest = null;
    return {
        request: function (config) {
            config.headers = config.headers || {};
            if ($cookies.get('token')) {
                config.headers.Authorization = 'Bearer ' + $cookies.get('token');
            }
            return config;
        },
        response: function (response) {
            var new_token = response.headers('authorization');
            if (new_token != null) {
                new_token = new_token.replace('Bearer ', '');
                $cookies.put("token", new_token);
            }

            if (response.status === 200) {
                return response || $q.when(response);
            } else {
                $rootScope.logout();
            }

        },
        responseError: function (rejection) {
            if (rejection.status === 401 && rejection.config.url == "api/checktokenexists") {
                // handle the case where the user is not authenticated and first attempt of refreshtoken is failed
                $rootScope.logout();
                return $q.reject(rejection);
            }
            // return response || $q.when(response);

            var $state = $injector.get("$state");

            // do something on error
            if (rejection.status === 400 && rejection.config.url.indexOf('logout') != -1) {
                $rootScope.logout();
                return $q.reject(rejection);
            }
            // Show the under maintenance mode
            if (rejection.status === 503) {
                $state.go('503');
                return $q.reject(rejection);
            }

            if ($state.current.name != 'login' && rejection.config.url.indexOf('logout') == -1) {
                //            if ($state.current.name != 'login' && rejection.status !== 400) {

                // do something on error
                if (rejection.status === 400) {
                    $rootScope.logout();
                    return $q.reject(rejection);
                }

                // Show the under maintenance mode
                if (rejection.status === 503) {
                    $state.go('503');
                    return $q.reject(rejection);
                }


                /* Validation Error or Server Internal Error handling*/
                if (rejection.status === 422 || rejection.status === 501) {
                    var new_token = rejection.headers('authorization');
                    if (new_token !== null) {
                        new_token = new_token.replace('Bearer ', '');
                        $cookies.put("token", new_token);
                    }
                    if (rejection.status === 501) {
                        // var Notification = $injector.get("Notification");
                        // Notification.error(rejection.data.error);
                        $rootScope.logout();
                        return $q.reject(rejection);
                    }
                }

                // If the error is 401 related
                if (rejection.status === 401) {
                    if(!inflightAuthRequest) {
                        inflightAuthRequest = $injector.get("loginFactory").refreshToken();
                    }
                    // We're going to get attempt to refresh the token on the
                    // server, if we're within the ttl_refresh period.
                    var deferred = $q.defer();
                    // We inject $http, otherwise we will get a circular ref

                    inflightAuthRequest.then(function (response) {
                        $cookies.put('token', response.data.token);
                        inflightAuthRequest = null;
                        deferred.resolve(response);
                    }, function errorCallback(response) {
                        // called asynchronously if an error occurs
                        // or server returns response with an error status.
                        inflightAuthRequest = null;
                        console.log(response);
                        $rootScope.logout();
                        deferred.reject(response);
                    });

                    return deferred.promise.then(function () {
                        return $injector.get("$http")(rejection.config);
                    });
                }

                return $q.reject(rejection);
            }

            return $q.reject(rejection);
        }
    };
});
